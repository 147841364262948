import React, { useEffect, useState } from "react";
import "./Findings.css";
import MultiSelectDropdown from "../../components/multiselect-dropdown/MultiSelectDropdown";
import { getFindingsData } from "../../api/Service";
import InfiniteScroll from "../../components/infinite-scroll";
import { BsInfoCircle } from "react-icons/bs";
import { OverlayTrigger, Tooltip } from "react-bootstrap"; 

const headers = [
  { key: "predicted_drg", value: "Audit Drg" },
  { key: "predicted_drg_revenue", value: "Audit Drg Revenue" },
  { key: "audit_rule_category", value: "Audit Rule Category" },
  {
    key: "audit_rule_long_description",
    value: "Audit Rule Long Description",
  },
  { key: "audit_rule_ref_no", value: "Audit Rule Number" },
  {
    key: "audit_rule_short_description",
    value: "Audit Rule Short Description",
  },
  { key: "current_drg_revenue", value: "Hospital Revenue" },
  { key: "drg_code", value: "Drg Code" },
  { key: "episode_identifier", value: "Episode Identifier" },
  { key: "file_name", value: "File Name" },
  { key: "insurer_identifier", value: "Insurer Identifier" },
  { key: "medical_record_number", value: "Medical Record Number" },
  { key: "principal_diagnosis_code", value: "Principal Diagnosis" },
];
const initialFiltersObj = {
  ruleCategory: [],
  refNos: [],
  drgCodes: [],
  diagnosisCodeOptions: [],
  priorityOptions: [],
  revenueOptions: [],
  coderNames: [],
};
const getObject = (option) => ({
  label: option,
  value: option,
});

const Findings = ({ filterOptions, loadId }) => { 
  const [filterToggle, setFilterToggle] = useState();
  const [filtersData, setFiltersData] = useState(initialFiltersObj);
  const [selectedFilters, setSelectedFilters] = useState(initialFiltersObj);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [potentialRevenueUplift, setPotentialRevenueUplift] = useState(0);
  const [episodeFlaggedByCerebrum, setEpisodeFlaggedByCerebrum] = useState(0);
  const [totalCasesFlagged, setTotalCasesFlagged] = useState(0);

  const formatToMillions = (num) => {
    return "$" +(num / 1_000_000).toFixed(2) + 'M';
  };

  const handleFilterToggle = () => {
    setFilterToggle(!filterToggle);
  };
  const handleSelectFilter = (name, selectedOptions) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [name]: selectedOptions?.map((option) => option.value),
    }));
  };
  const getData = (page = currentPage, loadMore) => {
    const body = {
      audit_rule_category: selectedFilters.ruleCategory,
      audit_rule_ref_no: selectedFilters.refNos,
      principal_diagnosis_code: selectedFilters.diagnosisCodeOptions,
      priority_value: selectedFilters.priorityOptions,
      current_drg_revenue: selectedFilters.revenueOptions,
      coder_name: selectedFilters.coderNames,
      pageNumber: page,
      load_id: loadId,
      drg_code: selectedFilters.drgCodes,
    };
    setIsLoading(true); 
    getFindingsData(body)
      .then((res) => {
        const {
          audited_rows,
          number_of_episode_flagged_by_cerebrum,
          percentage_of_total_cases_flagged,
          potential_revenue_uplift,
        } = res;
        if (loadMore) {
          setTableData([...tableData, ...audited_rows]);
        } else {
          setTableData(audited_rows);
        }
        setPotentialRevenueUplift(potential_revenue_uplift);
        setEpisodeFlaggedByCerebrum(number_of_episode_flagged_by_cerebrum);
        setTotalCasesFlagged(percentage_of_total_cases_flagged);
        const newPage = page + 1;
        setCurrentPage(newPage);
        setHasMore(true);
      })
      .catch((err) => {
        setHasMore(false);
       //console.log("FINDINGS_GET_DATA_ERR::", err.message);
        if (!loadMore) {
          setTableData([]);
        }
      })
      .finally(() => {
        //console.log("GET_DATA::::FINALLY", page);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getData(1);
  }, [selectedFilters, loadId]);
  useEffect(() => {
    if (Object.keys(filterOptions).length > 0) {
      // Extract options from filterOptions and update state
      const filtersObj = {
        ruleCategory: filterOptions.category_code?.map(getObject),
        refNos: filterOptions.current_ref_no?.map(getObject),
        drgCodes: filterOptions.drg_code?.map(getObject),
        diagnosisCodeOptions:
          filterOptions.principal_diagnosis_code?.map(getObject),
        priorityOptions: filterOptions.priority_value?.map(getObject),
        revenueOptions: filterOptions.inlier_rate_dollar?.map((option) => ({
          label: `$${(option / 1000).toFixed(2)}M`,
          value: option,
        })),
        coderNames: filterOptions.coder_name?.map(getObject),
      };
      setFiltersData(filtersObj);
    }
  }, [filterOptions]);

  return (
    <div className="finding-wrapper">
      <div className="top-calculations">
        <div className="potential-rev">
          <div style={{ display: "inline-block" }}>
            {" "}
            Potential Revenue Uplift{" "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  With an estimated average strike rate of 15% at an average DRG
                  uplift of $4K
                </Tooltip>
              }
            >
              <span style={{ cursor: "pointer" }}>
                <BsInfoCircle />
              </span>
            </OverlayTrigger>
          </div>
          <div style={{ color: "#032F49" }}>{formatToMillions(potentialRevenueUplift)}</div>
        </div>
        <div className="episodes-Flagged">
          <div style={{ display: "inline-block" }}>
            {" "}
            Number of Episodes Flagged by cerebrum{" "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Count number of Episodes where audit status ='Failed'
                </Tooltip>
              }
            >
              <span style={{ cursor: "pointer" }}>
                <BsInfoCircle />
              </span>
            </OverlayTrigger>
          </div>
          <div style={{ color: "#032F49" }}>{episodeFlaggedByCerebrum}</div>
        </div>
        <div className="percentage-total-flagged-cases">
          <div style={{ display: "inline-block" }}>
            {" "}
            Percentage of Total Cases Flagged{" "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Number of Cases Failing Audit divided by Total number of
                  Episodes Processed
                </Tooltip>
              }
            >
              <span style={{ cursor: "pointer" }}>
                <BsInfoCircle />
              </span>
            </OverlayTrigger>
          </div>
          <div style={{ color: "#032F49" }}>{totalCasesFlagged.toFixed()}%</div>
        </div>
      </div>
      <div className="findings-content-wrapper d-flex">
        <InfiniteScroll
          className="findings-table table-container"
          hasData={hasMore}
          loadMore={() => getData(currentPage, true)}
          isLoading={isLoading}
        >
          <h4 className="finding-heading">Findings</h4>
          <table>
            <thead>
              <tr>
                {headers.map((header) => (
                  <th key={header.key}>{header.value}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {headers.map((header) => (
                    <td key={header.key}>
                      <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip>{row[header.key]}</Tooltip>}
                      >
                        <span>{row[header.key]}</span>
                      </OverlayTrigger>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
        <div className="d-flex mt-2 gap-1">
          <div onClick={handleFilterToggle} className="filters-text">
            <span>filters</span>
          </div>
          {filterToggle && (
            <div className="findings-filter-content p-2">
              <div className="filter-header d-flex gap-5">
                <h6>Filter Pane</h6>
                <span
                  className="back-arrow-filter"
                  onClick={handleFilterToggle}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-return-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5"
                    />
                  </svg>
                </span>
              </div>
              <div className="text-start mb-1">
                Audit Rule Category
                <MultiSelectDropdown
                  options={filtersData.ruleCategory}
                  onSelect={(options) =>
                    handleSelectFilter("ruleCategory", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Audit Rule Number
                <MultiSelectDropdown
                  options={filtersData.refNos}
                  onSelect={(options) => handleSelectFilter("refNos", options)}
                />
              </div>
              <div className="text-start mb-1">
                DRG Code
                <MultiSelectDropdown
                  options={filtersData.drgCodes}
                  onSelect={(options) =>
                    handleSelectFilter("drgCodes", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Principal Diagnosis Code
                <MultiSelectDropdown
                  options={filtersData.diagnosisCodeOptions}
                  onSelect={(options) =>
                    handleSelectFilter("diagnosisCodeOptions", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Audit Priority
                <MultiSelectDropdown
                  options={filtersData.priorityOptions}
                  onSelect={(options) =>
                    handleSelectFilter("priorityOptions", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Hospital_Revenue
                <MultiSelectDropdown
                  options={filtersData.revenueOptions}
                  onSelect={(options) =>
                    handleSelectFilter("revenueOptions", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Coder Name
                <MultiSelectDropdown
                  options={filtersData.coderNames}
                  onSelect={(options) =>
                    handleSelectFilter("coderNames", options)
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Findings;
