import React, { useEffect, useState } from "react";
// import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import "./Page.css";
import AuditDetails from "../auditdetails/AuditDetails";
import AuditRows from "../audits/AuditRows";
import { initilaDetailsObj } from "../../utils/Constants";
import {
  getAuditRows,
  getFilteredAuditData,
  // getPassedRecords,
  // getPassedRecordsDetails,
  getauditData,
  recall_episode,
} from "../../api/Service";
import { Spinner } from "../../common/Helper";
import { useChatContext } from "../../../../../context";

function Page({isRowClicked, setIsRowClicked }) {
  const { webWorkFlow } = useChatContext();
  const { auditDetails, setAuditDetails } = webWorkFlow;
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [auditRows, setAuditRows] = useState([]);
  const [flagauditRows, setflagAuditRows] = useState(false);
  const [isRowsLoading, setIsRowsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState({
    insurer_identifier: [],
    priority_value: [],
    status: [],
    file_type: [],
    header_id: [],
    coder_name: [],
    admission_date_from: "",
    admission_date_to: "",
    separation_date_from: "",
    separation_date_to: "",
  });
  const [isP5Clicked, setIsP5Clicked] = useState(false);
  const [priorityVal5, setPriorityVal5] = useState(false);
  const [editFlageData, seteditFlageData] = useState();

  //console.log(isP5Clicked, "isP5Clicked:::");

  const getAuditDetailsById = async (hcp_line_id) => {
    //console.log('checkepiden',hcp_line_id);
    setIsDetailsLoading(true);
    // if (isP5Clicked) {
    //   return getPassedRecordsDetails(id)
    //     .then((data) => {
    //       const details = data.audited_rows[0];
    //       setAuditDetails({ ...details, id });
    //     })
    //     .catch((err) => {
    //      console.log(err.message);
    //       throw new Error(err.message);
    //     })
    //     .finally(() => {
    //       setIsDetailsLoading(false);
    //     });
    // }
    // return
    getauditData(hcp_line_id)
      .then((data) => {
        //console.log("DATA::::", data)
        const details = data.data.hcp_line_detail;
        setAuditDetails({ ...details, hcp_line_id });
        return { ...details, hcp_line_id };
      })
      .catch((err) => {
       // console.log(err.message);
        throw new Error(err.message);
      })
      .finally(() => {
        setIsDetailsLoading(false);
      });
  };
  const handleRowClick = (row) => {
    //console.log("ROW::::::", row)
    getAuditDetailsById(row.hcp_line_id);
    const index = auditRows.findIndex(
      (data) => data.hcp_line_id === row.hcp_line_id
    );
    setSelectedIndex(index);
    setIsRowClicked(true);
    setPriorityVal5(row.priority_value === 5 ? true : false);
  };
  // const unflage=(row)=>{
  //   console.log("AUDITROW::::::", row);
  //   console.log('clciked')
  //   getAuditDetailsById(row.hcp_line_id);
  //   const index =  flagauditRows.findIndex((data) => data.hcp_line_id === row.hcp_line_id);
  //   setSelectedIndex(index);
  //   setIsRowClicked(true);
  //   setPriorityVal5(row.priority_value===5?true:false);
  // }
  //console.log('sdf',flagauditRows);
  const updatedAuditRows = (rows) => {
    setAuditRows(rows.reverse());
  };
  const getAuditRowsByFilter = async (filters) => {
    
    try {
      updatedAuditRows([]);
      setIsRowsLoading(true);
      setAuditDetails({});
      setAppliedFilters({ ...appliedFilters, ...filters });
      setHasMore(true);
      setIsP5Clicked(false);
      const appliedfilter = { ...appliedFilters, ...filters, pageNumber: 1 };
      const data = await getFilteredAuditData(appliedfilter);
      //console.log('jadgshjash',data);
      if (data.length) {
        setHasMore(true);
      }
      updatedAuditRows(data);
      setIsRowsLoading(false);
    } catch (err) {
      setIsRowsLoading(false);
      setHasMore(false);
    }
  };
  const handleRefreshClick = () => {
    setAuditDetails({});
    getAuditRowsData();
    window.location.reload();
  };
  const getAuditRowsData = (page = 1) => {
    setIsRowsLoading(true);
    //console.log('checkstatus',flagauditRows);
    const fetchRows = flagauditRows
      ? recall_episode({ page_number: page })
      : getAuditRows(page, appliedFilters);
  
    fetchRows
      .then((data) => {
        const newRows = flagauditRows ? data.data.flagged_rows : data;
        if (newRows.length === 0) {
          setHasMore(false);
        }
        setAuditRows((prevRows) => [...prevRows, ...newRows]);
        setPage(page + 1);
      })
      .catch((err) => {
        //console.error("Error fetching audit rows:", err);
        setIsRowsLoading(false);
        setHasMore(false);
      })
      .finally(() => {
        setIsRowsLoading(false);
      });
  };
  
  const handlePreviousClick = () => {
    const index = selectedIndex - 1;
    getAuditDetailsById(
      auditRows[index].hcp_line_id,
      auditRows[index].priority_value
    );
    setSelectedIndex(index);
  };
  const handleNextClick = () => {
    const index = selectedIndex + 1;
    getAuditDetailsById(
      auditRows[index].hcp_line_id,
      auditRows[index].priority_value
    );
    setSelectedIndex(index);
  };
  const handleAuditDataUpdate = (hcp_line_id, status) => {
    getAuditDetailsById(hcp_line_id).then(() => {
      //getting
      const updatedRow = auditRows[selectedIndex];
      // updating
      updatedRow.hcp_line_id = hcp_line_id;
      updatedRow.status = status;
      //replacing in the same index
      auditRows[selectedIndex] = updatedRow;
    });
  };
  useEffect(() => {
    getAuditRowsData();
    window.scrollTo(0, 0);
  }, []);
  // useEffect(()=>{
  //   sessionStorage.setItem("flagStatus",flagauditRows);
  // },[flagauditRows])
  // console.log(auditDetails?.is_flagged,auditDetails);
  
  return (
    <div className="main-page h-100">
      {isRowClicked ? (
        <>
          {isDetailsLoading ? (
            <Spinner />
          ) : (
            <AuditDetails
              selectedIndex={selectedIndex}
              length={auditRows.length}
              auditDetails={auditDetails}
              handlePreviousClick={handlePreviousClick}
              handleNextClick={handleNextClick}
              setAuditDetails={setAuditDetails}
              getUpdatedAuditDetails={handleAuditDataUpdate}
              getAuditRowsData={getAuditRowsByFilter}
              setIsRowClicked={setIsRowClicked}
              priorityVal5={priorityVal5}
              flagauditRows={flagauditRows}
              editFlageData={editFlageData}
              setIsRowsLoading={setIsRowsLoading}
              setflagAuditRows={setflagAuditRows}
            />
          )}
        </>
      ) : (
        <div className="audit-content">
          <Navbar
            updatedAuditRows={updatedAuditRows}
            handleRefreshClick={handleRefreshClick}
            setAuditDetails={setAuditDetails}
            setIsRowsLoading={setIsRowsLoading}
            isRowsLoading={isRowsLoading}
            getAuditRowsByFilter={getAuditRowsByFilter}
            setHasMore={setHasMore}
            setPage={setPage}
            auditDetails={auditDetails}
            setAuditRows={setAuditRows}
            setflagAuditRows={setflagAuditRows}
            flagauditRows={flagauditRows}
            editFlageData={editFlageData}
            getAuditRowsData={getAuditRowsData}
          />
          <div className="audits w-100 h-100">
            <AuditRows
              handleRowClick={handleRowClick}
              auditRows={auditRows}
              isRowsLoading={isRowsLoading}
              selectedIndex={selectedIndex}
              hasMore={hasMore}
              auditDetails={auditDetails}
              flagauditRows={flagauditRows}
              loadMore={() => getAuditRowsData(page)}
              seteditFlageData={seteditFlageData}
              getAuditRows={getAuditRows}
              getAuditDetailsById={getAuditDetailsById}
              setSelectedIndex={setSelectedIndex}
              setIsRowClicked={setIsRowClicked}
              setPriorityVal5={setPriorityVal5}
            />
            {/* <div className="sidebar">
              <Sidebar
                updatedAuditRows={updatedAuditRows}
                setAuditDetails={setAuditDetails}
                getAuditRowsByFilter={getAuditRowsByFilter}
                handleP5PriorityClick={() => handleP5PriorityClick(1)}
              />
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default Page;
