
// export const configData = [
//   {
//     name: "DISCOVERY_COMPANION",
//     path: "/discovery-companion",
//     header: "Discovery Companion",
//   },
//   {
//     name: "WORKFLOW_COMPANION",
//     path: "/workflow-companion",
//     header: "Workflow Companion",
//   },
//   {
//     name: "AI_COMPANION",
//     path: "/chat-ai",
//     header: "AI Companion",
//   },
//   {
//     name: "DISCOVER_FEATURES",
//     path: "/discover-features",
//     header: "Discover Features",
//   },
// ];

export const configData = [
  {
    name: "DiscoveryCompanion",
    path: "/discovery-companion",
    header: "Discovery Companion",
  },
  {
    name: "WorkflowCompanion",
    path: "/workflow-companion",
    header: "Workflow Companion",
  },
  {
    name: "AICompanion",
    path: "/chat-ai",
    header: "AI Companion",
  },
  {
    name: "DiscoverFeatures",
    path: "/discover-features",
    header: "Discover Features",
  },
];