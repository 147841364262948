import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ children, requiredPermissions }) => {
    const { isAuthenticated, permissions } = useAuth(); 

    // Check if the user is authenticated
    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    // Flatten the permissions object into an array of permission strings
    const userPermissions = Object.entries(permissions).reduce((acc, [key, value]) => {
        if (typeof value === 'boolean' && value || Array) {
            acc.push(key);
        }else if (Array.isArray(value)) { value.forEach(perm => acc.push(`${key}.${perm}`)); }
        return acc;
    }, []);
    // console.log(requiredPermissions, "===================================================")
    // console.log(userPermissions, "------------------------------------------------------------")
    // If the route requires specific permissions, check if user has them
    if (requiredPermissions && !requiredPermissions.some(permission => userPermissions.includes(permission))) {
        // If not, redirect to a forbidden page or another route
        return <Navigate to="/forbidden" />;
    }

    // If the user has required permissions, render the children (protected content)
    return children;
};

export default ProtectedRoute;