// import React from "react";
import ChatPage from "./components/chatpage/ChatPage";
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ChatAIMainPage = () => {
  const navigate = useNavigate(); // useNavigate is used for programmatic navigation
  const permissions = JSON.parse(sessionStorage.getItem('permissions')); // Retrieve user permissions from storage
  
  useEffect(() => {
    if (permissions.admin) {
      return; // Admins have access to all routes
    }
      // Check if the user has the required permission
      if (!permissions?.AICompanion) {
          navigate('/no-access'); // Redirect to NoAccess page if permission is missing
      }
  }, [permissions, navigate]);
  return <ChatPage />;
};

export default ChatAIMainPage;
