// export const extractFileName = (fileName) => {
//   if (!fileName) return "";
//   const fn = fileName.split("/");
//   return fn[fn.length - 1];
// };
export const extractFileName = (filePath) => {
  if (!filePath) return "";
 
  // Remove any S3 protocol or HTTP prefix if present
  const cleanPath = filePath.replace(/^s3:\/\/|https:\/\/[^\/]+\//, "");
 
  // Split the cleaned path by `/` and get the last segment as the filename
  const pathSegments = cleanPath.split("/");
  const fileName = pathSegments[pathSegments.length - 1];
 
  return fileName || "No filename found";
};
export const extractLoadId = (fileName) => {
  if (!fileName) return "";
  const match = fileName.match(/(\d+)$/);  
  return match ? match[0] : "";
};
export const extractUserName = (name) => {
  if (!name) return "";
  const n = name.split(" ");
  return n[0];
};

export const formatDate = (inputDate) => {
  if (!inputDate) {
    return
  }
  let dateString = inputDate?.toString();

  // Check if the length of the input date string is 7 (e.g., `3032023`)
  if (dateString.length === 7) {
    dateString = "0" + dateString; // Add a leading zero if necessary
  }

  // Assuming the input is now in the form `ddmmyyyy`
  const day = dateString.substring(0, 2);
  const month = dateString.substring(2, 4);
  const year = dateString.substring(4, 8);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthIndex = parseInt(month) - 1;
  const monthName = monthNames[monthIndex];

  return `${day} ${monthName} ${year}`;
};

export const formatCalenderDate = (date) => {
  if (!date) return '';
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
