import React, { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../authContext/AuthContext";
import { authenticate } from "../../authContext/CognitoAuth"; 
import { BiLoader,BiShow, BiHide } from "react-icons/bi";
import ForgotPassword from "./ForgetPassword";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { configData } from "../navbar/config";

function Login({setShowLogin}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showLoginPage, setShowLoginPage] = useState(true);
  const navigate = useNavigate();
  const { setIsAuthenticated, setUserEmail,setPermissions } = useAuth();
  const [showPassword, setShowPassword] = useState(false);



const handleSubmit = async (event) => {
  event.preventDefault();
  setLoading(true);
  setError(""); // Reset error before attempting login

  const payload = {
    username: username,
    password: password,
  };

  try {
    const response = await axios.post("https://l0yn7um74k.execute-api.ap-southeast-2.amazonaws.com/admin_login_users/", payload);
    setLoading(false);

    if (response.data.statusCode !== 400) {
      // console.log(response.data.body, "%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")
      const { accessToken, message, permissions, user_email,user_id,organization_id,country_id} = response.data.body; // No need to parse
      //console.log('checkrepsonse',response.data.body,organization_id,country_id);
      // Show success message
      toast.success(message);

      // Store tokens and user info in session storage
      sessionStorage.setItem("loginSession", accessToken);
      sessionStorage.setItem("accessToken", accessToken); 
      sessionStorage.setItem("useremail", user_email); // Save email from response
      sessionStorage.setItem("userId", user_id); // Save email from response
      setUserEmail(user_email); // Set email in AuthContext
      sessionStorage.setItem("organizationId",organization_id);
      sessionStorage.setItem("countryId",country_id);
      // console.log(user_id,"***************************!!!!!!!!!!!!!!!!!");
      // Store theme preference in session storage
      sessionStorage.setItem("mode", "DARK"); // Set theme mode

      // Store permissions if available
      if (permissions) {
        sessionStorage.setItem("permissions", JSON.stringify(permissions)); // Store permissions in session storage
        setPermissions(permissions); // Update permissions in context
      }

      // Update sessionStorage storage and state
      sessionStorage.setItem("isAuthenticated", "true");
      setIsAuthenticated(true);
    //   const userPermissions = Object.entries(permissions).reduce((acc, [key, value]) => {
    //     if (typeof value === 'boolean' && value || Array) {
    //       acc.push(key);}
    //     return acc;
    // }, []);
    // console.log(userPermissions,"=============================================================================" ,typeof(userPermissions))
    // const filteredConfigData = configData.filter(item => {
    //   return userPermissions.includes(item.name);
     
    // });
    // console.log(filteredConfigData[0].path,"jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj")
    // navigate(`/${filteredConfigData[0].path.replace(/^\//, "")}`);
     navigate("/");
    } else {
      toast.error(JSON.parse(response.data.body));
    }
  } catch (error) {
    setLoading(false);
    console.error(error);
    toast.error("Login failed, please try again.");
  }
};
  return showLoginPage ? (
    <div className="login-container">
      <ToastContainer autoClose={20000} />
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>Login</h2>
        <div className="input-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              setError("");
            } }
            disabled={loading}
          />
        </div>
        <div className="input-group"  style={{
               display: "flex",
               alignItems: "center",
               position: "relative",
                }}>
          <label htmlFor="password">Password</label>
          <input
           type={showPassword ? "text" : "password"}
            id="password"
            value={password} 
            onChange={(e) => {
              setPassword(e.target.value);
              setError("");  
            }}
            disabled={loading}
          />
          <span
              onClick={() => setShowPassword(!showPassword)}
              style={{
                position: "absolute",
                right: "10px",
                cursor: "pointer",
                color: "#888",
                top: "50%",
              }}
            >
              {showPassword ? <BiHide /> : <BiShow />}
            </span>
  
        </div>
        <button
          className="Login-btn btn btn-warning"
          type="submit"
          disabled={loading}
        >
          {loading && !error ? <BiLoader /> : "Log In"}
        </button>
        {error && <p className="login-errorMessage">{error}</p>}
        <div>
          <p className="switchSignUp">
            Don't have an account? <span onClick={() => setShowLogin(true)}> Sign Up</span>
          </p>
          <p className="forgotPassword" onClick={() => setShowLoginPage(false)}>Forgot Password?</p>
        </div>
      </form>
    </div>
  ) : (
    <ForgotPassword setShowLoginPage={setShowLoginPage} />
  );
}

export default Login;