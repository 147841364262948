import React from 'react';

const NoAccessPage = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>Access Denied</h1>
            <p>You do not have permission to access this page. Please contact the administrator.</p>
        </div>
    );
};

export default NoAccessPage;