import "react-datepicker/dist/react-datepicker.css";
import DashboardScreen from "./pages/DashboardScreen";
import "./index.css"
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { SnackbarProvider } from "notistack";

function CoderCompanion() {
  const navigate = useNavigate(); // useNavigate is used for programmatic navigation
  const permissions = JSON.parse(sessionStorage.getItem('permissions')); // Retrieve user permissions from storage

  useEffect(() => {
    if (permissions.admin) {
      return; // Admins have access to all routes
    }
      // Check if the user has the required permission
      if (!permissions?.DiscoveryCompanion) {
          navigate('/no-access'); // Redirect to NoAccess page if permission is missing
      }
  }, [permissions, navigate]);
  return (
    
    <DashboardScreen />
  );
}

export default CoderCompanion;
