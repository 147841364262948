import React, { useState,useEffect } from "react";
import Login from "../pages/login/Login";
import Signup from "../pages/signup/SignUp";

const AuthComponent = () => {
  const [showLogin, setShowLogin] = useState(true);
  useEffect(()=>{
    if(!sessionStorage.loginSession){
      setShowLogin(false)
    }
  },[])
  return showLogin ?<Signup setShowLogin={setShowLogin} />: (
    <Login setShowLogin={setShowLogin} />
  )
};

export default AuthComponent;
