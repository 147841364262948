import React, { useState } from "react";
import "./PromptTemplate.css";
import { Dropdown } from "react-bootstrap";
import { IoSend } from "react-icons/io5";
export const dropdownData = [
  {
    id: 1,
    label: "Act as a clinical coder",
    template_text:
      "Act as a Senior Health Information Manager and Clinical Coder in Australia, with 20 years’ experience in Australian Clinical Coding Standards. You are trying to assign the appropriate clinical coding for a recent patient episode and unfortunately the clinician has potentially missed a few documented steps in the documentation of the procedure and treatment of the patient. The procedure was <<Add Procedure>>. <<Add Diagnosis>> is not mentioned in the body of the report. Generate a multiple-choice CDQ that I can send to the clinician to clarify the episode of care and more accurately assign coding complexity.",
  },
  {
    id: 2,
    label: "Act as a Senior HIM",
    template_text:
      "Act as a Senior Health Information Manager and Clinical Coder in Australia, with 20 years experience in Australian Clinical Coding Standards. You are trying to assign the appropriate clinical coding for a recent patient episode. Patient has presented with the following: <<Add Diagnosis>> and the following procedures were performed <<Add Procedure>> What coding should be applied?",
  },
];

function PromptTemplate({ setFinalTotalText }) {
  const [editDiagnosis, setEditDiagnosis] = useState(false);
  const [editProcedure, setEditProcedure] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [diagnosisValue, setDiagnosisValue] = useState("");
  const [procedureValue, setProcedureValue] = useState("");
  const [id, setId] = useState(1);

  const renderEditableField = () => {
    const filteredText = dropdownData.find((value) => value.id === id);
    if (!filteredText) return null;

    const templateText = filteredText.template_text;

    const renderTextWithTextarea = () => {
      const parts = templateText.split(
        /(<<Add Procedure>>|<<Add Diagnosis>>)/g
      );
      return parts.map((part, index) => {
        

        if (part === "<<Add Procedure>>" && editProcedure) {
          return (
            <>
              <textarea
                name="Add Procedure"
                placeholder="<<Add Procedure>>"
                key={index}
                rows="2"
                cols="20"
                value={procedureValue || ""}
                onChange={(e) => setProcedureValue(e.target.value)}
                onBlur={() => setEditProcedure(false)}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.stopPropagation();
                  }

                  if (e.key === "Enter") {
                    e.preventDefault();
                    setEditProcedure(false);
                  }
                }}
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
            </>
          );
        }

        if (part === "<<Add Diagnosis>>" && editDiagnosis) {
          return (
            <textarea
              name="Add Diagnosis"
              placeholder="<<Add Diagnosis>>"
              key={index}
              rows="2"
              cols="20"
              value={diagnosisValue || ""}
              onChange={(e) => setDiagnosisValue(e.target.value)}
              onBlur={() => setEditDiagnosis(false)}
              onKeyDown={(e) => {
                if (e.key === " ") {
                  e.stopPropagation();
                }

                if (e.key === "Enter") {
                  e.preventDefault();
                  setEditDiagnosis(false);
                }
              }}
              style={{
                width: "100%",
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          );
        }

        return <span key={index}>{part}</span>;
      });
    };

    const finalText = templateText
      .replace(
        "<<Add Procedure>>",
        procedureValue + "<<Add Procedure>" || "<<Add Procedure>>"
      )
      .replace(
        "<<Add Diagnosis>>",
        "<<Add Diagnosis>>" + diagnosisValue || "<<Add Diagnosis>>"
      );
      console.log(finalText)

    return (
      <div>
        {editDiagnosis || editProcedure ? (
          <div>{renderTextWithTextarea()}</div>
        ) : (
            
          <p>{finalText}</p>
        )}
      </div>
    );
  };

  const handelSendPrompt = () => {
    const filteredText = dropdownData.find((value) => value.id === id);
    if (!filteredText) return;

    let finalText = filteredText.template_text
      .replace("<<Add Procedure>>", procedureValue || "")
      .replace("<<Add Diagnosis>>", diagnosisValue || "");

    finalText = finalText
      .replace("<<Add Procedure>>", "")
      .replace("<<Add Diagnosis>>", "");
    setFinalTotalText(finalText + "What coding should be applied?");

    setDropdownOpen(false);
    setEditProcedure(false);
    setEditDiagnosis(false);
  };

  const handleToggleDropdown = (id) => {
    setDropdownOpen(dropdownOpen === id ? null : id);
    setProcedureValue("");
    setDiagnosisValue("");
    setEditDiagnosis(false);
    setEditProcedure(false);
    setId(id);
  };

  return (
    <>
      <div className="header-wrapper">
        <div style={{ display: "flex", gap: "10px" }}>
          {dropdownData.map((dropdown) => (
            <Dropdown
              className="drop-down"
              show={dropdownOpen === dropdown.id}
              onToggle={() => handleToggleDropdown(dropdown.id)}
              key={dropdown.id}
              autoClose={false}
            >
              <Dropdown.Toggle className="dropdown-toggle-header">
                {dropdown.label}
              </Dropdown.Toggle>
              <Dropdown.Menu className="drop-down-menu">
                <Dropdown.Item key={dropdown.id} eventKey={dropdown.id}>
                  {renderEditableField()}
                </Dropdown.Item>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    className="edit-prompt-buttons"
                    onClick={() => {
                      setEditDiagnosis(true);
                      setEditProcedure(false);
                    }}
                  >
                    Add Diagnosis
                  </button>
                  <button
                    className="edit-prompt-buttons"
                    onClick={() => {
                      setEditProcedure(true);
                      setEditDiagnosis(false);
                    }}
                  >
                    Add Procedure
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ marginTop: "10px" }}>
                    What coding should be applied?
                  </p>
                  <IoSend
                    onClick={handelSendPrompt}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </Dropdown.Menu>
            </Dropdown>
          ))}
        </div>
      </div>
    </>
  );
}

export default PromptTemplate;
