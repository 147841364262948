import Page from "./components/page/Page";
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const WebWorkFlowMain = ({isRowClicked, setIsRowClicked}) => {
  const navigate = useNavigate(); // useNavigate is used for programmatic navigation
  const permissions = JSON.parse(sessionStorage.getItem('permissions')); // Retrieve user permissions from storage
  
  useEffect(() => {
      // Check if the user has the required permission
      if (permissions.admin) {
        return; // Admins have access to all routes
      }
      if (!permissions?.WorkflowCompanion) {
          navigate('/no-access'); // Redirect to NoAccess page if permission is missing
      }
  }, [permissions, navigate]);
  return <Page isRowClicked={isRowClicked} setIsRowClicked={setIsRowClicked}/>;
};

export default WebWorkFlowMain;
