
import React, { useEffect, useState } from 'react';
import Modal from '../modal/Modal';
import './ModuleManagement.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../../../axiosInterceptor';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ModuleManagement = () => {
  const [modules, setModules] = useState();
  const [selectedModule, setSelectedModule] = useState(null);
  const [moduleName, setModuleName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [refresh, setrefresh] = useState(false);

  // State for confirming delete action
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [moduleToDelete, setModuleToDelete] = useState(null);
  const [users, setUsers] = useState([]);

  const handleAddModule = () => {
    if (!moduleName.trim()) {
      toast.error("Module name cannot be empty!");
      return;
    }


    axiosInstance.post("https://91ptd7je10.execute-api.ap-southeast-2.amazonaws.com/post_admin_modules/", { moduleName, createdBy: sessionStorage.userId })
      .then((response) => {
        toast.success(response.data.message);
        setShowModal(false);
      })
      // .catch(err => {
      //   setShowModal(false);
      // });
      .catch(err => {
        // Check for duplicate key error
        if (err.response && err.response.data && err.response.data.error) {
          const errorMessage = err.response.data.error;
          if (errorMessage.includes("duplicate key value violates unique constraint")) {
            toast.error("Module name already exists. Please choose a different name.");
          } else {
            toast.error("An error occurred while adding the module.");
          }
        } else {
          toast.error("Unable to connect to the server. Please try again.");
        }
        setShowModal(false);
      });
  };

  const handleEditModule = (module) => {
    setSelectedModule(module);
    setModuleName(module.module_name);
    setShowModal(true);
  };

  const handleUpdateModule = () => {
    axiosInstance.put("https://b8358xsyf9.execute-api.ap-southeast-2.amazonaws.com/update_admin_modules/", { 
      newModuleName: moduleName, 
      newupdatedBy: sessionStorage.userId, 
      moduleId: selectedModule.module_id 
    })
      .then((response) => {
        toast.success(response.data.message);
        setShowModal(false);
      })
      .catch(err => {
        setShowModal(false);
      });
  };

  const handleDeleteModule = (module) => {
    // Set the module to be deleted and show the confirmation dialog
    setModuleToDelete(module);
    setShowConfirmDialog(true);
  };

  const confirmDelete = () => {
    if (moduleToDelete) {
      axiosInstance.delete(`https://eo7t661rda.execute-api.ap-southeast-2.amazonaws.com/delete_admin_modules/?moduleId=${moduleToDelete.module_id}`)
        .then((response) => {
          toast.success(response.data.message);
          setShowConfirmDialog(false);
          setModuleToDelete(null); // Clear the module after deletion
        })
        // .catch(err => {
        //   setShowConfirmDialog(false);
        //   setModuleToDelete(null);
        // });
        .catch((err) => {
          setShowConfirmDialog(false);
          setModuleToDelete(null);
  
          // Handle specific backend errors
          if (
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.includes("violates foreign key constraint")
          ) {
            toast.error("This module is assigned to roles and cannot be deleted.");
          } else {
            toast.error("An error occurred while deleting the module.");
          }
        });
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
    setModuleToDelete(null); // Clear the module if cancellation happens
  };

  useEffect(() => {
    axiosInstance.get("https://lsuvwue040.execute-api.ap-southeast-2.amazonaws.com/get_admin_modules/")
      .then((response) => {
        setModules(response.data.data);
      })
      .catch(err => {
        console.log("Error: " + err);
      });

  }, [refresh]);

  return (
    <div className="module-management-container">
      <ToastContainer autoClose={20000} />
      <div className="header-section-module">
          <button className='my-2 add-module-btn' onClick={()=>setrefresh(!refresh)}>Refresh Data</button>
        {/* <h1 className="title-left-module">Manage Modules</h1> */}
        <button
          className="btn btn-primary add-module-btn"
          onClick={() => {
            setShowModal(true);
            setSelectedModule(null);
          }}
        >
          Add Module
        </button>
      </div>
      <table className="module-table">
        <thead>
          <tr>
            <th>Module ID</th>
            <th>Module Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {modules ? modules.map((module) => (
            <tr key={module.module_id}>
              <td>{module.module_id}</td>
              <td>{module.module_name}</td>
              <td>
                {/* <button onClick={() => handleEditModule(module)}>
                  <FontAwesomeIcon icon={faPenToSquare} className="iconuser" />
                </button> */}
                <button onClick={() => handleDeleteModule(module)}>
                  <FontAwesomeIcon icon={faTrash} className="iconuser" />
                </button>
          
              </td>
            </tr>
          )) : <div className="container">Loading...</div>}
        </tbody>
      </table>

      {showModal && (
        <Modal
          name={moduleName}
          setName={setModuleName}
          onSave={selectedModule ? handleUpdateModule : handleAddModule}
          onClose={() => setShowModal(false)}
          title={selectedModule ? 'Edit Module' : 'Add Module'}
          label="Module Name"
        />
      )}

      {/* Confirm Delete Dialog */}
      {showConfirmDialog && (
        <div className="modal-organization">
          <div className="modal-content-organization">
            <h3>Confirm Delete</h3>
            <p>Are you sure you want to delete this module?</p>
            <div className="modal-footer-organization">
              <button className="btn-organization save-organization" onClick={confirmDelete}>Delete</button>
              <button className="btn-organization cancel-organization" onClick={cancelDelete}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModuleManagement;
