import React, { useState, useEffect } from 'react';
import './UserRoleAssignment.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
// import axiosInstance from '../../../server';
import axiosInstance from '../../../axiosInterceptor';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const UserRoleAssignment = () => {
  const [userList, setUserList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const fetchRolesAndUsers = async () => {
    try {
      const rolesResponse = await axiosInstance.get("https://7t0ywwlaqb.execute-api.ap-southeast-2.amazonaws.com/get_admin_roles/");
      const roles = rolesResponse.data.data;

      // Filter out the "admin" role
      const filteredRoles = roles.filter(
        (role) => role.role_name.toLowerCase() !== "admin\n"
      );
      setRolesList(filteredRoles);
      
      const usersResponse = await axiosInstance.get("https://lgshvoc3q9.execute-api.ap-southeast-2.amazonaws.com/get_admin_users");
      const users = usersResponse.data.data.map(user => ({
        user_id: user.user_id,
        username: user.username,
      }));
      setUserList(users);

      const userRolesResponse = await axiosInstance.get("https://o760ky59a6.execute-api.ap-southeast-2.amazonaws.com/get_user_roles/");
      setUserRoles(userRolesResponse.data.data);
    } catch (error) {
      console.log("Error fetching roles or users: ", error);
    }
  };

  useEffect(() => {
    fetchRolesAndUsers();
  }, []);

  const openAssignRoleModal = () => {
    setSelectedUserId(null);
    setSelectedRoles([]);
    setIsEditing(false);
    setShowRoleModal(true);
  };

  const handleUserChange = (event) => {
    setSelectedUserId(event.target.value);
    const user = userList.find(u => u.user_id === parseInt(event.target.value));
    setSelectedRoles(user ? user.roles : []);
  };

  const handleRoleChange = (selectedOptions) => {
    const updatedRoles = selectedOptions.map(option => option.value);
    setSelectedRoles(updatedRoles);
  };

  const assignRoles = async () => {
    if (!selectedUserId || selectedRoles.length === 0) {
      setErrorMessage("Please select a user and at least one role.");
      return;
    }
    const createdBy = sessionStorage.userId;
    try {
      const roleAssignments = selectedRoles.map(role => {
        const roleObj = rolesList.find(r => r.role_name === role);
        const roleId = roleObj ? roleObj.role_id : null;
        if (roleId) {
          const payload = {
            user_id: parseInt(selectedUserId),
            role_id: roleId,
            created_by: createdBy
          };
          return axiosInstance.post("https://mai3tged3c.execute-api.ap-southeast-2.amazonaws.com/post_user-roles", payload);
        }
        return Promise.resolve();
      });
      await Promise.all(roleAssignments);
      setShowRoleModal(false);
      toast.success('Roles assigned successfully".');
      fetchRolesAndUsers();
    } catch (error) {
      console.log("Error assigning roles:", error);
      toast.error('Failed to assign roles. Please try again.');
      setErrorMessage("Failed to assign roles. Please try again.");
    }
  };

  const handleEdit = (user) => {
    setSelectedUserId(user.user_id);
    const userRolesForEdit = userRoles
    .filter(roleAssignment => roleAssignment.user_id === user.user_id)
    .map(roleAssignment => {
      const role = rolesList.find(r => r.role_id === roleAssignment.role_id);
      return role ? role.role_name : null;
    })
    .filter(roleName => roleName !== null); // Filter out any null values

  setSelectedRoles(userRolesForEdit);
    setIsEditing(true);
    setShowRoleModal(true);
  };

  const updateRoles = async () => {
    if (!selectedUserId) {
      setErrorMessage("Please select a user and at least one role.");
      return;
    }
    const updatedRoleIds = selectedRoles.map(roleName => {
      const roleObj = rolesList.find(role => role.role_name === roleName);
      return roleObj ? roleObj.role_id : null;
    }).filter(roleId => roleId !== null);
    const payload = {
      user_id: selectedUserId,
      role_id: updatedRoleIds
    };
    try {
      await axiosInstance.post("https://5uux2jyzy4.execute-api.ap-southeast-2.amazonaws.com/update_admin_roles_ics_v1", payload);
      setShowRoleModal(false);
      toast.success("Roles updated successfully.");
      fetchRolesAndUsers();
    } catch (error) {
      // console.log("Error updating roles:", error);
      toast.error("Failed to update roles. Please try again.");
      setErrorMessage("Failed to update roles. Please try again.");
    }
  };

  const closeModal = () => {
    setShowRoleModal(false);
    setErrorMessage('');
  };

  const userHasRole = (userId, roleId) => {
    return userRoles.some(assignment => assignment.user_id === userId && assignment.role_id === roleId);
  };

  return (
    <div className="user-role-assignment">
         <ToastContainer autoClose={20000} />
      <div className="header-userroleassign">
         <h2></h2>
        <button onClick={openAssignRoleModal}>Assign Role</button>
      </div>

      <div className="table-wrapper">
        <table className="user-role-table">
          <thead>
            <tr >
              <th className="fixed-column fixed-left usernamerole">Username</th>
              {rolesList.map(role => (
                <th key={role.role_id}>{role.role_name}</th>
              ))}
              <th className="fixed-column ">Actions</th>
            </tr>
          </thead>
          <tbody >
            {userList ? userList.map((user) => (
              <tr key={user.user_id}>
                <td className="fixed-column  fixed-left">{user.username}</td>
                {rolesList.map(role => (
                  <td key={role.role_id}>
                    {userHasRole(user.user_id, role.role_id) ? (
                      <FontAwesomeIcon icon={faCircleCheck} className="icon-checked" />
                    ) : (
                      <FontAwesomeIcon icon={faCircleXmark} className="icon-unchecked" />
                    )}
                  </td>
                ))}
                <td className="fixed-column">
                  <button onClick={() => handleEdit(user)}>
                    <FontAwesomeIcon icon={faPenToSquare} className="iconuser" />
                  </button>
                </td>
              </tr>
            )) : <><div className='container'>loading....</div></>}
          </tbody>
        </table>
      </div>

      {showRoleModal && (
        <div className="modal-userroleassign">
          <div className="modal-content-userroleassign">
            <h3>Assign Roles to User</h3>
            <label className='user-role-label'>Select User</label>
            <select className="selectuseroption" value={selectedUserId} onChange={handleUserChange}>
              <option value="">Select User</option>
              {userList.map(user => (
                <option value={user.user_id} key={user.user_id}>{user.username}</option>
              ))}
            </select>

            <div className="role-selection">
              <label className='user-role-label'>Select Roles</label>
              <Select
                className="userroleassignment"
                options={rolesList.map(role => ({ value: role.role_name, label: role.role_name }))}
                isMulti
                value={selectedRoles && selectedRoles.map(role => ({ value: role, label: role }))}
                onChange={handleRoleChange}
                placeholder="Select roles..."
              />
            </div>

            <div className="role-container">
              {selectedRoles && selectedRoles.map(role => (
                <div key={role} className="selected-role-tag">{role}</div>
              ))}
            </div>

            {errorMessage && <p className="error-message">{errorMessage}</p>}

            <div className="modal-footer-userroleassign">
              {isEditing ? (
                <button onClick={updateRoles}>Update</button>
              ) : (
                <button onClick={assignRoles}>Save Permission</button>
              )}
              <button onClick={closeModal} className="cancel-button">Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserRoleAssignment;


