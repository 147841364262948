import React, { createContext, useContext, useState, useEffect } from "react"; 
import AuthComponent from "./AuthComponent";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const user_email = sessionStorage.getItem("useremail");
  const storedPermissions = JSON.parse(sessionStorage.getItem("permissions") || "[]");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState('')
  const [isLoading, setIsLoading] = useState(true);  
  const [permissions, setPermissions] = useState(storedPermissions);

  useEffect(() => { 
    if (user_email) {
      const authStatus = sessionStorage.getItem("isAuthenticated") === "true";  
      setIsAuthenticated(authStatus);
      setUserEmail(user_email);
      setPermissions(storedPermissions);
    }else {
      setUserEmail("")
      setPermissions([]);
    }
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }
// console.log('ISAUTH::::', isAuthenticated)
  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, userEmail, setUserEmail ,permissions, setPermissions }}>
      {isAuthenticated ? children : <AuthComponent />}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);