import logo from "../assets/discoverycompanionlogo.png";
import logo2 from "../assets/workflowcompanionlogo.png";
import logo3 from "../assets/chatailogo.png";
import tick from "../assets/icon+.png";
import tack from "../assets/icon-.png";

export const landingPageData = [
  {
    id: 1,
    title: "Discovery Companion",
    description: "We care about the Coders. The Coder Companion optimises the efforts of Clinical Coders, providing assurance to maximise ROI and drive greater outcomes for healthcare providers.",
    image: logo,
    rightDescription: "Simple, ready to use tool - reduce the need for ad-hoc manual audits and use right away",
    features: [
      "Monthly subscription. Unlimited users & patient episodes.",
      "Simple, ready to use tool - reduce the need for ad-hoc manual audits and use right away.",
      "Payback in 1 month."
    ],
    button1: { text: "Active module", icon: tick },
    button2: { text: "Demo", icon: tack },
    route: "/discovery-companion",
    name:"DiscoveryCompanion",
    demoLink: "https://vimeo.com/1023832896/9c3c36de2a"
  },
  {
    id: 2,
    title: "Workflow Companion",
    description: "The Workflow Companion is the comprehensive solution for maximising coding effectiveness, integrating with existing systems to deliver greater efficiency through automation. Live coding advice and AI to perform automatic CDQ Generation.",
    image: logo2,
    rightDescription: "Automated intelligence built for greater efficiency within your existing workflows",
    features: [
      "Monthly subscription",
      "FHIR, HL7 or RESTFul API driven.",
      "Expands on the Coder Companion with integration into PAS and other systems."
    ],
    button1: { text: "Unlock Module", icon: tick },
    button2: { text: "Demo", icon: tack },
    route: "/workflow-companion",
    name:"WorkflowCompanion",
    demoLink: "https://vimeo.com/1023833397/abfd65e6a1"
  },
  {
    id: 3,
    title: "AI Companion",
    description: "The complete intelligent solution and Clinical Coder’s ultimate tool to expedite research time. AI Companion uses artificial intelligence to deliver complete support with live coding advice and GenAI which helps drive Clinical Documentation Improvement Programs. Acting as your guide and always there for any questions you may have for HIMs and Hospital Management/Executive.",
    image: logo3,
    rightDescription: "Automated, AI powered operational support and insights for real-time advice",
    features: [
      "Monthly subscription.",
      "Expands on the Integrated Coder Companion solution.",
      "Live coding advice and Coder Companion GPT.",
      "Maximum ROI with maximum intelligent coding support."
    ],
    button1: { text: "Unlock Module", icon: tick },
    button2: { text: "Demo", icon: tack },
    route: "/chat-ai",
    name:"AICompanion",
    demoLink: "https://vimeo.com/1023833598/fa44c5f4f1"
  }
];
