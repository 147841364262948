import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "../authContext/ProtectedRoute";
import CoderCompanion from "../projects/users/codercompanion";
import FeedbackForm from "../projects/users/feedback/Feedback";
import LandingCards from "../pages/landingPage/LandingCards/LandingCards";
import NotificationDetails from "../pages/navbar/NotificationDetails";
import ViewDashboard from "../projects/users/codercompanion/pages/ViewDashboard";
import ChatAIMainPage from "../projects/users/chat-ai";
import WebWorkFlowMain from "../projects/users/web-workflow";
import PowerApp from "../projects/users/Power-Apps";
import UserFeedback from "../projects/users/feedback/UserFeedbacks";
import RoleManagement from "../projects/admin/rolemanagement/RoleManagement";
import ModuleManagement from "../projects/admin/modulemangement/ModuleManagement";
import RoleModulePermissions from "../projects/admin/rolemodulepermission/RoleModulePermissions";
import UserRoleAssignment from "../projects/admin/userroleassignment/UserRoleAssignment";
import Userindex from "../projects/admin/users/index";
import ManageOrganizations from "../projects/admin/manageorganizations/ManageOrganizations";
import ManageCountries from "../projects/admin/managecountries/ManageCountries";
import NoAccessPage from "../unauthorizedpage/NoAccessPage";
import ChatHomePage from "../projects/users/chat-ai/components/chatpage/ChatHomePage";
import GeneralChat from "../projects/users/chat-ai/components/chatpage/GeneralChat";

const RoutesPage = ({ isRowClicked, setIsRowClicked }) => {
  return (
    <Routes>
        <Route path="/no-access" element={<NoAccessPage />} />
       <Route
                path="/"
                element={<Navigate to="/discover-features" />}
            /> 
      <Route
        path="feedback"
        element={
          <ProtectedRoute requiredPermissions={["Feedback", "admin"]}>
            <FeedbackForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="user-feedbacks"
        element={
          <ProtectedRoute requiredPermissions={["UserFeedback", "admin"]}>
            <UserFeedback />
          </ProtectedRoute>
        }
      />
      <Route
        path="/discover-features"
        element={
          <ProtectedRoute requiredPermissions={["DiscoverFeatures", "admin"]}>
            <LandingCards />
          </ProtectedRoute>
        }
      />
      <Route
        path="/discovery-companion"
        element={
          <ProtectedRoute requiredPermissions={["DiscoveryCompanion", "admin"]}>
            <CoderCompanion />
          </ProtectedRoute>
        }
      />
      <Route
        path="/chat-ai"
        element={
          <ProtectedRoute requiredPermissions={["AICompanion", "admin"]}>
            <ChatAIMainPage/>
          </ProtectedRoute>
        }
      />
       {/* <Route
        path="/chat-ai/ics-australia"
        element={
          <ProtectedRoute>
            <ChatAIMainPage/>
          </ProtectedRoute>
        }
      />
         <Route
        path="/chat-ai/general-chat"
        element={
          <ProtectedRoute>
            <GeneralChat/>
          </ProtectedRoute>
        }
      /> */}
      <Route
        path="/notifications"
        element={
          <ProtectedRoute requiredPermissions={["Notifications", "admin"]}>
            <NotificationDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="viewdashboard"
        element={
          <ProtectedRoute requiredPermissions={["DiscoveryCompanion", "admin"]}>
            <ViewDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/workflow-companion"
        element={
          <ProtectedRoute requiredPermissions={["WorkflowCompanion", "admin"]}>
            <WebWorkFlowMain
              isRowClicked={isRowClicked}
              setIsRowClicked={setIsRowClicked}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="powerapp"
        element={
          <ProtectedRoute requiredPermissions={["PowerApp", "admin"]}>
            <PowerApp />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute requiredPermissions={["admin"]}>
            <Userindex />
          </ProtectedRoute>
        }
      />
      <Route
        path="/role-management"
        element={
          <ProtectedRoute requiredPermissions={["admin"]}>
            <RoleManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path="/module-management"
        element={
          <ProtectedRoute requiredPermissions={["admin"]}>
            <ModuleManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path="/role-module-permission"
        element={
          <ProtectedRoute requiredPermissions={["admin"]}>
            <RoleModulePermissions />
          </ProtectedRoute>
        }
      />
      <Route
        path="/user-role-assignment"
        element={
          <ProtectedRoute requiredPermissions={["admin"]}>
            <UserRoleAssignment />
          </ProtectedRoute>
        }
      />
      <Route
        path="/manage-organization"
        element={
          <ProtectedRoute requiredPermissions={["admin"]}>
            <ManageOrganizations />
          </ProtectedRoute>
        }
      />
      <Route
        path="/manage-countries"
        element={
          <ProtectedRoute requiredPermissions={["admin"]}>
            <ManageCountries />
          </ProtectedRoute>
        }
      />
      {/* <Route
        path="recallepisode"
        element={
          <ProtectedRoute> 
            <RecallEpisode/>
          </ProtectedRoute>
        }
      /> */}
    </Routes>
  );
};

export default RoutesPage;
