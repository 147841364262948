import React, { useEffect, useState } from "react";
import "./AuditRows.css";
import { extractFileName, extractUserName } from "../../utils/util";
import InfiniteScroll from "../../../codercompanion/components/infinite-scroll";

export const getColor = (status) => {
  //console.log(status, "statuscolor:::");
  const red = [
    "Flagged for Review",
    "Reviewed,no change",
    "Reviewed,query required",
  ]; // #F8698521;
  const blue = [
    "Reviewed,reclaimed",
    "Reviewed,rebilled",
    "Reviewed,query required",
  ]; // #00395B
  const gray = ["Reviewed,query sent", "Reviewed,recoded"]; // #344054;
  const passed = "passed"; //#E4F5FF
  const failed = "Failed"; //#feecef;
  if (passed === status) {
    return "passed";
  }
  if (failed === status) {
    return "failed";
  }

  if (red.includes(status)) return "red";
  if (blue.includes(status)) return "blue";
  if (gray.includes(status)) return "gray";
};

const AuditRowCard = ({
  handleRowClick,
  row,
  isActive,
  flagauditRows,
  unflage,
  auditDetails
}) => {
  //console.log("flagauditRows", flagauditRows);
  // console.log('newsssss',row)
  return (
    <>
      <tr
        className={`auditrow ${isActive && "active"}`}
        onClick={() => {
          flagauditRows ? unflage(row) : handleRowClick(row);
        }}
      >
        {/* <td>
          <input type="checkbox"   onClick={(e) => {
        e.stopPropagation(); // Prevent the row click event from triggering
      }} />
        </td> */}
        <td>
          {" "}
          {row.priority_value &&
          row.priority_value.some((val) => val !== null) ? (
            row.priority_value.map((value, index) => (
              <span
                key={index}
                className={`audit-count p${value}`}
                style={{ marginRight: "5px" }}
              >
                P{value}
              </span>
            ))
          ) : (
            <span className="audit-count p5">P5</span>
          )}
        </td>
        <td>{row.file_name}</td>
        <td>{extractUserName(row.name)}</td>
        <td>{row.date}</td>
        <td>
          {row?.audit_rule_short_description?.map((eachDesc, index) => {
            return (
              <span key={index}>
                {eachDesc}
                {index < row.audit_rule_short_description.length - 1 && ", "}
              </span>
            );
          })}
        </td>
        <td>
          <div className={`audit-status-btn ${getColor(row.status)}`}>
            {row.status}
          </div>
        </td>
        <td>{row.episode_identifier}</td>
      </tr>
    </>
  );
};

function AuditRows({
  handleRowClick,
  auditRows,
  selectedIndex,
  hasMore,
  loadMore,
  isRowsLoading,
  flagauditRows,
  auditDetails,
  seteditFlageData,
  getAuditDetailsById,
  setSelectedIndex,
  setIsRowClicked,
  setPriorityVal5,
}) {
  let unflage = (row) => {
    //console.log("My row", row);
    seteditFlageData(row);
    //console.log("AUDITROW::::::", row);
    //console.log('clciked')
    getAuditDetailsById(row.hcp_line_id);
    const index =  auditRows.findIndex((data) => data.hcp_line_id === row.hcp_line_id);
    setSelectedIndex(index);
    setIsRowClicked(true);
    setPriorityVal5(row.priority_value===5?true:false);
  };
  //console.log(flagauditRows,'jyug',auditRows);
  return (
    <>
     <InfiniteScroll
        className=" audits-main"
        hasData={hasMore}
        loadMore={() => loadMore()}
        isLoading={isRowsLoading}
      >
        <table id="audits-main" className="">
          <thead>
            <tr>
              {/* <th>
                <input type="checkbox" />
              </th> */}
              <th>Select All</th>
              <th>Document Name</th>
              <th>User Name</th>
              <th>Date</th>
              <th>Description</th>
              <th>Status</th>
              <th>Episode Identifier</th>
            </tr>
          </thead>
          <tbody>
            {auditRows && auditRows.map((row, index) => (
              <AuditRowCard
                key={index}
                row={row}
                handleRowClick={handleRowClick}
                isActive={selectedIndex === index}
                flagauditRows={flagauditRows}
                unflage={unflage}
                auditDetails={auditDetails}
                
              />
            ))}
          </tbody>
        </table>
      </InfiniteScroll>
    </>
  );
}

export default AuditRows;
