import React, { useEffect, useState } from "react";
import Sidebar from "../../projects/users/chat-ai/components/sidebar/Sidebar";
import { useChatContext } from "../../context";
import {
  fetchArchiveChat,
  getChatConversation,
  getConvo,
} from "../../projects/users/chat-ai/api/Service";

const ChatSideBar = () => {
  const { chatAI, scroll } = useChatContext();
  const {
    conversations,
    setConversations,
    setIsFirstMessage,
    setSelectedModal,
    setMessages,
    setActiveConversation,
    setArchivedChats,
    setIsMessagesLoading,
  } = chatAI;
  const {
    isConversationsLoading,
    setIsConversationsLoading,
    hasMoreConversations,
    setHasMoreConversations,
    currentPage,
    setCurrentPage,
  } = scroll;

  const getConversations = async (pageNum, loadMore) => {
    try {
      setIsConversationsLoading(true);
      const messages = await getConvo(pageNum);
      if (loadMore) {
        setConversations([...conversations, ...messages]);
      } else {
        setConversations(messages);
      }
      setHasMoreConversations(true);
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
    } catch (err) {
     //console.log("get_convo_error:::", err.message);
      setHasMoreConversations(false);
      if (!loadMore) {
        setConversations([]);
      }
    } finally {
      setIsConversationsLoading(false);
    }
  };
  const getConversationMessages = async (id) => {
    let response;
    try {
      setIsMessagesLoading(true);
      if(id){
         response = await getChatConversation(id);
      }
      const { conversations, isVersion2 } = response;
      if (isVersion2) {
        setSelectedModal("VERSION_2");
      } else {
        setSelectedModal("VERSION_1");
      }
      setMessages(conversations);
      setIsFirstMessage(false);
    } catch (err) {
      console.log("CONVO_MESSAGES_ERR::", err);
    } finally {
      setIsMessagesLoading(false);
    }
  };
  const handleConversationClick = (convo) => {
    setActiveConversation(convo);
    getConversationMessages(convo.conversation_id);
  };
  const fetchArchivedConversations = async () => {
    try {
      const response = await fetchArchiveChat();
      //console.log(response,'archiveresponse');
      setArchivedChats(response);
    } catch (err) {
     console.log("Error in Archived request:", err.message);
    }
  };
  const handleNewChatBtnClick = () => {
    setActiveConversation({});
    setIsFirstMessage(true);
    setMessages([]);
  };
  useEffect(() => {
    getConversations(1);
  }, []);
  //onsole.log("CHAT_AI::", chatAI);
  return (
    <div className="light-sidebar">
      <Sidebar
        conversations={conversations}
        height="300px"
        handleClick={handleConversationClick}
        handleNewChatBtnClick={handleNewChatBtnClick}
        setIsOpen={() => {}}
        isOpen={false}
        getConversations={getConversations}
        fetchArchivedConversations={fetchArchivedConversations}
        setMessages={setMessages}
        isLoading={isConversationsLoading}
        getConversationsFn={getConversations}
        hasMoreConversationsVal={hasMoreConversations}
        currentPageNum={currentPage}
      />
    </div>
  );
};

export default ChatSideBar;
