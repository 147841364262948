import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuditRuleDetailsHeader from "./components/header/Header";
import AuditRuleDetails from "./components/auditruledetails/AuditRuleDetails";
import { ToastContainer } from 'react-toastify';

const PowerApp = () => {
  const navigate = useNavigate(); // useNavigate is used for programmatic navigation
  return (
    <>
      {/* <AuditRuleDetailsHeader /> */}
      <ToastContainer position="top-center"/>
      <AuditRuleDetails />
    </>
  );
};

export default PowerApp; 