const notifications=[
    {
        "username":"Riya8291",
        "dateandtime": "06-11-2024, 11:30 AM",
        "fileName":"Header.csv",
        "fileSize":"10mb"
    },
    {
        "username":"SaiRamireddy",
        "dateandtime": "03-11-2024, 1:30 PM",
        "fileName":"HeaderNew.csv",
        "fileSize":"15mb"
    },
    {
        "username":"Sanjay",
        "dateandtime": "06-11-2024, 11:30 AM",
        "fileName":"HeaderandNew.csv",
        "fileSize":"14mb"
    },
    {
        "username":"Prathyusha",
        "dateandtime": "27-01-2024, 11:30 AM",
        "fileName":"Controls.csv",
        "fileSize":"10mb"
    },
    {
        "username":"Swetha",
        "dateandtime": "14-05-2024, 11:30 AM",
        "fileName":"Compalins.csv",
        "fileSize":"12mb"
    },
    {
        "username":"Amrutha",
        "dateandtime": "13-08-2024, 11:30 AM",
        "fileName":"HeaderFile.csv",
        "fileSize":"11mb"
    },
    {
        "username":"Harshith",
        "dateandtime": "13-08-2024, 11:30 AM",
        "fileName":"HeaderFile.csv",
        "fileSize":"11mb"
    },
    {
        "username":"sagar",
        "dateandtime": "13-08-2024, 11:30 AM",
        "fileName":"HeaderFile.csv",
        "fileSize":"11mb"
    },
    {
        "username":"Jyoti",
        "dateandtime": "13-08-2024, 11:30 AM",
        "fileName":"HeaderFile.csv",
        "fileSize":"11mb"
    },
    {
        "username":"Jyoti",
        "dateandtime": "13-08-2024, 11:30 AM",
        "fileName":"HeaderFile.csv",
        "fileSize":"11mb"
    },
    {
        "username":"Jyoti",
        "dateandtime": "13-08-2024, 11:30 AM",
        "fileName":"HeaderFile.csv",
        "fileSize":"11mb"
    },

]
export default notifications;