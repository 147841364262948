import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./RcmLineGraph.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ data }) => { 

  const options = {
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false, // Disable gridlines on x-axis
        },
        title: {
          display: true,
          text: 'Months', // Label for the x-axis
          font: {
            size: 14,
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Hospital Revenue', // Label for the x-axis
          font: {
            size: 14,
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const index = context.dataIndex;
            const { difference, data, auditDrugRevenue } = context.dataset;
            const hospitalRev = data[index];
            const auditRev = auditDrugRevenue[index];
            const diff = difference[index];
            return `Hospital Revenue: $${hospitalRev}\nAudit Revenue: $${auditRev}\nDifference: $${diff}`;
          },
        },
      },
      legend: {
        display: false, // Disable the legend
      },
      title: {
        display: true,
        text: 'Unique Episode Count that has been flagged (from audited table)', // Top title of the chart
        font: {
          size: 16,
        },
      },
    },
  };
  return (
    <div className="line-graph">
      <Line data={data} options={options} height={280} />
    </div>
  );
};

export default LineChart;
