import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import _, { debounce } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button,Dropdown, Form, ToastContainer } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {
  getauditData,
  getAuditFiles,
  getAuditRows,
  getAuditRowsByFileName,
  getSearchData,
  getSearchRecommendations,
  recall_episode,
} from "../../api/Service";
import refresh from "../../assests/refresh.png";
import SearchIcon from "../../assests/search-lg.png";
import { FilterLines } from "../../assests/svgImages/Images";
import CloseFilter from "../../assests/x-close.png";
import { extractFileName } from "../../utils/util";
import WorkFlowFilters from "../filters";
import "./Navbar.css";
import { Spinner } from "../../common/Helper";
import InfiniteScroll from "../../../codercompanion/components/infinite-scroll";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../../../../axiosInterceptor";
import { toast } from "react-toastify";

function Navbar({
  updatedAuditRows,
  handleRefreshClick,
  setAuditDetails,
  setIsRowsLoading,
  isRowsLoading,
  getAuditRowsByFilter,
  setHasMore,
  setAuditRows,
  setflagAuditRows,
  flagauditRows,
  getAuditRowsData,
  setPage,
  editFlageData
}) {
  const [showModal, setShowModal] = useState(false);
  const [searchEpisodeIdentifier, setSearchEpisodeIdentifier] = useState("");
  const [recommendations, setRecommendations] = useState([]);
  const [isRecommendationsLoading, setIsRecommendationsLoading] =
    useState(false);
  const [auditFiles, setAuditFiles] = useState([]);
  const [isRecallLoading,setIsRecallLoading]=useState(false);
  const navigate=useNavigate();
  const [appliedFilters, setAppliedFilters] = useState({
    insurer_identifier: [],
    priority_value: [],
    status: [],
    file_type: [],
    coder_name: [],
    admission_date: null,
    separation_date: null,
    header_id: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMany, setHasMany] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectAllFiles, setSelectAllFiles] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const fetchRecommendations = useCallback(debounce((identifier) => {
      setIsRecommendationsLoading(true);
      getSearchRecommendations(identifier,flagauditRows)
        .then((res) => {
          if(res.message){
            toast.error(res.message)
          }
          const { audited_rows } = res.data;
          setRecommendations(audited_rows);
        })
        .catch((err) =>
         console.log("Search Recommendations Error::", err.message)
        )
        .finally(() => setIsRecommendationsLoading(false));
    }, 500),
  
    [flagauditRows]
  
  );

  const handleSearchRecommendClick = (rowId) => {
    setSearchEpisodeIdentifier(rowId);
    getSearchResults(rowId);
  };
  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchEpisodeIdentifier(value);
    if (value) {
      fetchRecommendations(value);
    } else {
      setRecommendations([]);
      setIsRecommendationsLoading(false);
    }
  };

  const getSearchResults = (searchData) => {
    //console.log('searchData',searchData);
    setIsRowsLoading(true);
    getSearchData(searchData,flagauditRows)
      .then((response) => {
        //console.log('UpdatedAudit',response.data.audited_rows);
        updatedAuditRows(response.data.audited_rows);
        setAuditDetails({});
        setSearchEpisodeIdentifier("");
        setHasMore(false);
        setRecommendations([]);
      })
      .catch((err) =>console.log("Search Results Error::", err.message))
      .finally(() => {
        setIsRowsLoading(false);
      });
  };

  const handleFilterClick = async (latestFilters = {}) => {
    const filters = { ...latestFilters, header_id: appliedFilters?.header_id };
    setAppliedFilters(filters);
    getAuditRowsByFilter(filters);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      getSearchResults(searchEpisodeIdentifier);
    }
  };

  const arraysHaveSameElements = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    for (let item of set1) {
      if (!set2.has(item)) {
        return false;
      }
    }
    return true;
  };

  function handleSelectChange(event, fileName) {
    // console.log("CHANGE::", fileName);
    const { value } = event.target;
    let selectedFields = [];
    let selectedFileNames = [];
    if (event.target.checked) {
      selectedFields = [...appliedFilters.header_id, value];
      selectedFileNames = [...appliedFilters.file_type, fileName];
    } else {
      selectedFields = [...appliedFilters.header_id];
      selectedFileNames = [...appliedFilters.file_type];
      const indexOfID = selectedFields.indexOf(value);
      const indexOfFiletype = selectedFileNames.indexOf(fileName);
      selectedFields.splice(indexOfID, 1);
      selectedFileNames.splice(indexOfFiletype, 1);
      // console.log("CHANGE::1", selectedFields);
    }
    const filters = {
      ...appliedFilters,
      header_id: selectedFields,
      file_type: selectedFileNames,
    };
    const allLoadIds = [];
    const allFileNames = [];
    auditFiles.forEach((file) => {
      allLoadIds.push(file.header_id);
      allFileNames.push((file.file_name));
    });
    // console.log("IDS::::", allLoadIds, selectedFields);

    if (
      arraysHaveSameElements(allLoadIds, selectedFields) &&
      arraysHaveSameElements(allFileNames, selectedFileNames)
    ) {
      setSelectAllFiles(true);
    } else {
      setSelectAllFiles(false);
    }
    setAppliedFilters(filters);
    getAuditRowsByFilter(filters);
  }
  const handleBreadCrumbClose = (key) => {
    handleFilterClick({ ...appliedFilters, [key]: [] });
  };

  const handleSelectAllCheckboxes = (e) => {
    const { checked } = e.target;
    let selectedFields = [];
    let selectedFileNames = [];
    setSelectAllFiles(checked);
    if (checked) {
      // console.log("ALL:::", e.target.checked, auditFiles);
      auditFiles.forEach((file) => {
        selectedFields.push(file.header_id);
        selectedFileNames.push((file.file_name));
      });
    } else {
      selectedFields = [];
      selectedFileNames = [];
    }
    const filters = {
      ...appliedFilters,
      header_id: selectedFields,
      file_type: selectedFileNames,
    };
    //console.log("FILTERS::::", filters);
    setAppliedFilters(filters);
    getAuditRowsByFilter(filters);
  };

  // key is to match with appliedFilters keys
  const breadCrumbs = [
    { label: "Status", key: "status" },
    { label: "Priority Value", key: "priority_value" },
    { label: "Insurer Identifier", key: "insurer_identifier" },
  ];
  //console.log("APPLIED:::", appliedFilters, auditFiles);
  const getData = async(page, load_more) => {
    try {
      setIsLoading(true);
      const response = await getAuditFiles(page);
      //console.log("AUDIT_FILES:::", response);
      if (load_more) {
        setAuditFiles([...auditFiles, ...response]);
      } else {
        setAuditFiles(response);
      }
      setHasMany(true);
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
    } catch (error) {
     console.log("Error fetching audit files:", error);
      setHasMany(false);
      if (!load_more) {
        setAuditFiles([]);
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData(1);
  }, []);
  //console.log('allauditfiles',flagauditRows);
  return (
    <div className="navbar">
      <div className="navbar-left-section">
        <input
        className="navbar-left-checkbox"
          type="checkbox"
          checked={selectAllFiles}
          onChange={handleSelectAllCheckboxes}
        />
        <Dropdown className="hcp-file-dropdown-webworkflow">
          <Dropdown.Toggle id="dropdown-basic">Select All</Dropdown.Toggle>
          <Dropdown.Menu className="webworkflow-dropdown-menu">
              <InfiniteScroll
                className="load-filters"
                hasData={hasMany}
                loadMore={() => getData(currentPage, true)}
                isLoading={isLoading}
              >
                <div>
                  {auditFiles.map((item) => (
                    <Form.Check
                      key={item.header_id}
                      type="checkbox"
                      label={`${item.start_date} - ${
                        item.end_date
                      } (${item.file_name.map((eachFileItem)=>(eachFileItem))})`}
                      value={item.header_id}
                      checked={
                        selectAllFiles || appliedFilters.header_id?.includes(String(item.header_id))
                       
                      }
                      onChange={(e) =>
                        handleSelectChange(e, (item.file_name.map((eachFileItem)=>(eachFileItem))))
                      }
                      style={{ width: "350px" }}
                    />
                  ))}
                </div>
              </InfiniteScroll>
          </Dropdown.Menu>
        </Dropdown>

        <div className="status-Filter-values d-flex gap-2">
          {breadCrumbs.map((breadCrumb) =>
            appliedFilters[breadCrumb.key]?.length > 0 ? (
              <div className={`${breadCrumb.key}-value d-flex`}>
                <p className="ellpsis">
                  {" "}
                  {breadCrumb.label}:
                  {appliedFilters[breadCrumb.key].map((items) => items + ",")}{" "}
                </p>
                <span
                  onClick={() => handleBreadCrumbClose(breadCrumb.key)}
                  className="close-filter-btn"
                >
                  <img src={CloseFilter} alt="Close" />
                </span>
              </div>
            ) : null
          )}
        </div>
      </div>
      <div className="navbar-right-section">
        {!flagauditRows && <div className="filter-wrapper" onClick={toggleModal}>
          <span>Filters</span>
          {/* <img src={filterlines} alt="filterlines" /> */}
          <FilterLines />
        </div>}
        {!flagauditRows && <span className="workflow-refresh-btn" onClick={handleRefreshClick}>
          <strong>Refresh Data</strong>
          <span>
            <img src={refresh} alt="img" />
          </span>
        </span>}
        <div className="search-bar">
          <img src={SearchIcon} alt="search-icon" className="search-icon" />
          <input
            className="audit-search"
            type="text"
            placeholder="Search by Patient Episode Identifier"
            onKeyDown={handleKeyPress}
            value={searchEpisodeIdentifier}
            onChange={handleSearch}
          />
          <div className="search-recommendations">
            {isRecommendationsLoading ? (
              <div className="loader">
                <Spinner />
              </div>
            ) : (
              recommendations?.map((item, idx) => (
                <li
                  key={idx}
                  onClick={() =>
                    handleSearchRecommendClick(item.episode_identifier)
                  }
                  className="search-recommendation"
                >
                  {item.episode_identifier}
                </li>
              ))
            )}
          </div>
        </div>
        <div>

        {!flagauditRows && <button className="recall-btn"  onClick={()=>{
          setflagAuditRows(true);
          //sessionStorage.setItem("flagStatus",flagauditRows);
          // Clear existing rows and reset pagination
          //setIsRowsLoading(true);
          setAuditRows([]);
          setPage(1);
          setHasMore(true);
      
          // Fetch flagged rows
          // getAuditRowsData(1);
          recall_episode({page_number:1}).then((res) =>{
            //console.log("auditRows Res",res);
            if(res.data.message){
              toast.error(res.data.message);
            }
            //getAuditRows({});
            // setAuditRows(res.res.audited_rows)
            setAuditRows(res.data.data.audited_rows); // Create a new array 
            // setflagAuditRows(true);
            
          }).catch((err) => {
            console.log("Error: " + err.message);
            
          })
        }}>Recall Episode</button>}

        {
          flagauditRows && 
         <div className="d-flex gap-2">
          <button className="recall-btn" onClick={()=>window.location.reload()}>Back</button></div>
        }

      </div>
      </div>


      {/*  Modal */}
      <WorkFlowFilters
        showModal={showModal}
        onApply={handleFilterClick}
        onCancel={toggleModal}
      />
    </div>
  );
}

export default Navbar;
