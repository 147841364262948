const ChatVersions =[
    {version: "VERSION_1",version_id:1},
    {version: "VERSION_2",version_id:2},
    {version: "VERSION_3",version_id:2},
    {version: "VERSION_4",version_id:4},

]



export default ChatVersions
