import React, { useState, useEffect, useRef, useCallback } from "react";
import _ from "lodash";
import { Button, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faCaretDown,
  faChevronRight,
  faChevronDown,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./AuditRuleDetails.css";
import {
  fetchTableData,
  addAuditRule,
  searchAuditRule,
  fetchCategories,
  addCategory,
  updateAuditRule,
} from "../../api/service";
import InfiniteScroll from "../../../codercompanion/components/infinite-scroll";
import { Spinner } from "../../../web-workflow/common/Helper";

const AuditRuleDetails = () => {
  const initialFormData = {
    reference_no: "",
    category: "",
    short_description: "",
    long_description: "",
    user_message: "",
    value_of_rule: " ",
    new_drg: "",
    rule1: "",
    rule2: "",
    created_by: "",
    rule1_updated: "",
    rule2_updated: "",
    drg_version: "",
    priority_value: "",
  };
  const initialUpdateForm = {
    reference_number: "",
    long_description: "",
    short_description: "",
    user_message: "",
    value_of_rule: "",
    new_drg: "",
    rule1: "",
    rule2: "",
    last_updated_by: "",
    rule1_updated: "",
    rule2_updated: "",
    drg_version: "",
    priority_value: "",
    status: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [updateFormData, setUpdateFormData] = useState(initialUpdateForm);
  //(updateFormData, "updateformdata:::::");
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showUpdateAuditModal, setshowUpdateAuditModal] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const tableWrapperRef = useRef(null);
  const [loadedPages, setLoadedPages] = useState(new Set()); // Track loaded pages
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [searchMessage, setSearchMessage] = useState("");
  const [categoryFormData, setCategoryFormData] = useState({
    category: "",
    category_code: ""
    
  });
  const isAnyCheckboxChecked = () => {
    return checkedItems.some((item) => item);
  };
  const [touched, setTouched] = useState({
    short_description: false,
    long_description: false,
    user_message: false,
    rule1: false,
    
  });

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true,
    }));
  };

  const handleHeaderCheckboxChange = () => {
    const newAllChecked = !allChecked;
    setAllChecked(newAllChecked);
    setCheckedItems(new Array(tableData.length).fill(newAllChecked));
  };

  const handleRowCheckboxChange = (index) => {
    const newCheckedItems = new Array(checkedItems.length).fill(false);
    if (!checkedItems[index]) {
      newCheckedItems[index] = true;
    }
    setCheckedItems(newCheckedItems);
    setAllChecked(newCheckedItems.every((item) => item));
  };

  const populateForm = (data) => {
    // setFormData(data);
    setUpdateFormData(data);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleShowCategoryModal = () => setShowCategoryModal(true);
  const handleCloseCategoryModal = () => setShowCategoryModal(false);
  const handleShowUpdateAuditModal = () => setshowUpdateAuditModal(true);
  const handleCloseUpdateAuditModal = () => setshowUpdateAuditModal(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setCategoryFormData({
      ...categoryFormData,
      [name]: value.toUpperCase(),
    });
  };
  const handleUpdateChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUpdateFormData({
      ...updateFormData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  //  handle submition for create audit rule
  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log("FormData before submit:", formData); // Debug log

    // Validate required fields
    if (!formData.category) {
      alert("Category is a mandatory field. Please select a category.");
      return;
    }
    const payload = {
      category: formData.category,
      short_description: formData.short_description,
      long_description: formData.long_description,
      user_message: formData.user_message,
      value_of_rule: null,
      new_drg: formData.new_drg,
      rule1: formData.rule1,
      rule2: formData.rule2,
      drg_version: formData.drg_version,
      priority_value: formData.priority_value === "" ? null : Number(formData.priority_value),
    };

    try {
      const newAuditRule = await addAuditRule(payload);
      setTableData((prevData) => {
        const updatedData = [...prevData, newAuditRule];

        return Array.from(
          new Map(updatedData.map((item) => [item.reference_no, item])).values()
        );
      });
      await loadAllTableData();
      handleCloseModal();
      toast.success("successfully created the audit rule");
      setFormData(initialFormData);
    } catch (error) {
     console.log("Error adding audit rule:", error);
     
    }
  };
  // handle submit for category
  const handleCategorySubmit = async (e) => {
    e.preventDefault();
  
    //console.log("Category Form Data:", categoryFormData);  // Add this log to check the state before sending
  
    const payload = {
      category: categoryFormData.category,
      category_code: categoryFormData.category_code,
        // static or dynamic value as required
    };
  
    // if (!payload.category || !payload.category_code) {
    //   console.log("Missing required fields in the payload:", payload);
    // }
  
    try {
      const response = await addCategory(payload);
      const statusCode= response.status;
      //console.log(response,statusCode,'auditrulescheck');
      if (statusCode === 202) {
        toast.success(`Category '${categoryFormData.category}' added successfully`);
        handleCloseCategoryModal();
        loadCategories();
        setCategoryFormData({ category: "", category_code: "" });
      } else if (statusCode === 409) {
        const warningMessage = response.data.message;
        toast.warn(`Warning: ${warningMessage}`);
      } else {
        const errorMessage = response.data.message || "Some fields are missing.";
        toast.error(`Error: ${errorMessage}`);
      }
    }  catch (error) {
      //console.error("Error occurred:", error);
      toast.error(`Error: ${error.response ? error.response.data.message : error.message}`);
    }
  };
  
  
  // handle submit for update audit rule
  const handleUpdateAuditSubmit = async (e) => {
    e.preventDefault();


    // const createdBy = sessionStorage.getItem("useremail") || "default_user"

    const payload = {
      reference_number: updateFormData.reference_no,
      short_description: updateFormData.short_description,
      long_description: updateFormData.long_description,
      user_message: updateFormData.user_message,
      value_of_rule: null,
      new_drg: updateFormData.new_drg,
      rule1: updateFormData.rule1,
      rule2: updateFormData.rule2,
      drg_version: updateFormData.drg_version,
      priority_value:  updateFormData.priority === "" ? null : Number(updateFormData.priority),
      // status: "",
    };

    try {
      const updateAudit = await updateAuditRule(payload); // Use the refactored function

      setTableData((prevData) => {
        const updatedData = [...prevData, updateAudit];
        return Array.from(
          new Map(updatedData.map((item) => [item.reference_no, item])).values()
        );
      });

      await loadAllTableData();
      handleCloseUpdateAuditModal();
      toast.success(`${updateFormData.reference_no} successfully updated`);
    } catch (error) {
     console.log("Error updating audit rule:", error);
    }
  };

  const loadCategories = async () => {
    try {
      const categories = await fetchCategories();
      setCategories(categories);
    } catch (error) {
     console.log("Error loading categories:", error);
    }
  };

  const loadTableData = async (pageNumber) => {
    if (!hasMoreData || loading || loadedPages.has(pageNumber)) return;

    setLoading(true);
    try {
      const newData = await fetchTableData(pageNumber);
      if (newData.length === 0) {
        setHasMoreData(false);
      } else {
        setTableData((prevData) => {
          const uniqueData = [...prevData, ...newData];

          return Array.from(
            new Map(
              uniqueData.map((item) => [item.reference_no, item])
            ).values()
          );
        });
        setCheckedItems((prevCheckedItems) => [
          ...prevCheckedItems,
          ...new Array(newData.length).fill(false),
        ]);
      }
      setLoadedPages((prevPages) => new Set(prevPages.add(pageNumber)));
    } catch (error) {
     console.log("Error loading table data:", error);
    } finally {
      setLoading(false);
    }
  };
  const loadAllTableData = async () => {
    setLoading(true);
    try {
      const allData = await fetchTableData(1); // Fetching first page data
      setTableData(allData);
      setCheckedItems(new Array(allData.length).fill(false));
      setCurrentPage(1);
      setHasMoreData(true);
      setLoadedPages(new Set([1]));
    } catch (error) {
     console.log("Error loading all table data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleScroll = () => {
    if (tableWrapperRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = tableWrapperRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        loadMoreData();
      }
    }
  };

  const debouncedSearch = useCallback(
    _.debounce(async (searchValue) => {
      setSearchLoading(true);
      if (searchValue.trim() === "") {
        setSearchMessage("");
        loadAllTableData();
        setSearchLoading(false);
        return;
      }
      try {
        // const isLowercase=searchValue!=searchValue.toLowerCase();
        // console.log(isLowercase);
        
        // const finalSearchValue=isLowercase?searchValue.toLowerCase():searchValue;
        // console.log(finalSearchValue);
        console.log(searchValue);
        
        const data = await searchAuditRule(searchValue.toUpperCase());
        console.log('searchval',searchValue)
        if (data.length === 0) {
          setSearchMessage("No results found");
        } else {
          setSearchMessage("");
        }
        setTableData(data);
        setCheckedItems(new Array(data.length).fill(false));
        setHasMoreData(false);
      } catch (error) {
       console.log("Error searching audit rule:", error);
        setSearchMessage(error.response.data.message);
      } finally {
        setSearchLoading(false);
      }
    }, 500),
    []
  );

  const handleSearch = (e) => {
    const searchValue = e.target.value.toUpperCase();
    setSearchTerm(searchValue);
    debouncedSearch(searchValue);
  };

  const loadMoreData = () => {
    if (loading || !hasMoreData) return;
    setCurrentPage((prevPage) => {
      const nextPage = prevPage + 1;
      loadTableData(nextPage);
      return nextPage;
    });
  };
  const [expandedRows, setExpandedRows] = useState([]);

  const handleRowClick = (index) => {
    setExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(index)) {
        return prevExpandedRows.filter((i) => i !== index);
      } else {
        return [index];
      }
    });
  };

  useEffect(() => {
    loadCategories(); // Initial category fetch
  }, []);

  useEffect(() => {
    loadTableData(currentPage); // Initial data fetch
  }, [currentPage]);

  useEffect(() => {
    const tableWrapper = tableWrapperRef.current;
    if (tableWrapper) {
      tableWrapper.addEventListener("scroll", handleScroll);
      return () => tableWrapper.removeEventListener("scroll", handleScroll);
    }
  }, [loading, hasMoreData]);
  //console.log(updateFormData, "updated form");
  return (
    <div>
      <div className="auditrulepage-wrapper">
        <div className="auditrule-form-wrapper"></div>
        <h3 className="h3-style">Settings &gt; Audit Rule Details</h3>
        <div className="audit-rule-header-wrapper">
          <div className="audit-rule-header">
            <h2>Audit Rules Details</h2>
          </div>
          <div className="right-side">
            <button
              className="btn-add-category"
              onClick={handleShowCategoryModal}
            >
              Add Category <span className="plus-sign">+</span>
            </button>
            <div className="create-audit-rule">
              <button
                className="btn-create-audit-rule"
                onClick={handleShowModal}
              >
                Create Audit Rule <span className="plus-sign">+</span>
              </button>
            </div>
            <div className="search-bar">
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="search-icon"
              />
              <input
                type="text"
                placeholder="Search Audit Rule"
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
        <div
          className="auditrule-table-wrapper"
          ref={tableWrapperRef}
          style={{ maxHeight: "1076px", overflow: "auto" }}
        >
          <InfiniteScroll
            loadMore={loadMoreData}
            hasData={hasMoreData}
            isLoading={loading}
          >
            {searchLoading ? (
              <div>
                <Spinner />
              </div>
            ) : searchTerm && searchMessage ? (
              <table id="auditRuleTable" className="table">
                <thead>
                  <tr>
                    <th className="checkbox-wrapper"></th>
                    <th>Reference No</th>
                    <th>Category</th>
                    <th>Short Description</th>
                    <th>Long Description</th>
                    <th>User Message</th>
                    <th>New Drg</th>
                    <th>Rule 1</th>
                    <th>Rule 2</th>
                    <th>DRG Version</th>
                    <th>Priority Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      className="reference"
                      colSpan="10"
                      style={{
                        color: "#d9534f",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {searchMessage}
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <table id="auditRuleTable" className="table">
                <thead>
                  <tr>
                    {/* <th className="checkbox-wrapper">
                      <input
                        type="checkbox"
                        checked={allChecked}
                        onChange={handleHeaderCheckboxChange}
                      />
                    </th> */}
                    <th>Reference No</th>
                    <th>Category</th>
                    <th>Short Description</th>
                    {/* <th>Long Description</th>
                    <th>User Message</th> */}
                    <th>New Drg</th>
                    <th>Rule 1</th>
                    <th>Rule 2</th>
                    <th>DRG Version</th>
                    <th>Priority Value</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, index) => (
                    <>
                      <tr
                        className={`main-row ${
                          expandedRows.includes(index) ? "expanded" : ""
                        }`}
                        key={index}
                        onClick={() => populateForm(row)}
                      >
                        {/* <td className="checkbox-wrapper">
                          <input
                            type="checkbox"
                            checked={checkedItems[index]}
                            onChange={() => handleRowCheckboxChange(index)}
                          />
                        </td> */}
                        <td>{row?.reference_no?.toUpperCase() ?? "-"}</td>
                        <td>{row?.category ?? "-"}</td>
                        <td>{row?.short_description ?row.short_description :"-"}</td>
                        {/* <td>{row?.long_description ?? "-"}</td>
                      <td>{row?.user_message ?? "-"}</td> */}
                        <td>{row?.new_drg ? row.new_drg : "-"}</td>

                        <td>{row?.rule1 ?row.rule1: "-"}</td>
                        <td>{row?.rule2 ?row.rule2 : "-"}</td>
                        <td>{row?.drg_version ?row.drg_version :"-"}</td>
                        <td>{row?.priority ?row.priority: "-"}</td>
                        <td onClick={() => handleRowClick(index)}>
                          <FontAwesomeIcon
                            icon={
                              expandedRows.includes(index)
                                ? faChevronDown
                                : faChevronRight
                            }
                          />
                        </td>
                      </tr>
                      {expandedRows.includes(index) && (
                        <tr className="expanded-row">
                          <td colSpan="10">
                            <div className="expanded-content">
                              <div className="expanded-info">
                                <div className="long-description">
                                  <strong className="longdescription-text-color">Long Description</strong>
                                  <div>{row?.long_description ?row?.long_description: "-"}</div>
                                </div>
                                <div className="user-message">
                                <strong className="user-text-color">User Message</strong>
                                  <div>{row?.user_message ?row.user_message :"-"}</div>
                                </div>
                              </div>
                              <div className="edit">
                                {" "}
                                <button
                                  className="edit-button"
                                  onClick={handleShowUpdateAuditModal}
                                >
                                  Edit{" "}
                                  <FontAwesomeIcon
                                    icon={faPencilAlt}
                                    className="ml-1"
                                  />
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </table>
            )}
          </InfiniteScroll>
          {/* modal form for create audit rules */}
        </div>
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          backdrop="static"
          keyboard={false}
          className="custom-modal"
          dialogClassName="modal-dialog-centered"
        >
          <Modal.Header closeButton>
            <Modal.Title>Create Audit Rule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <div className="form-row">
                <Form.Group
                  controlId="formReferenceNumber"
                  className="form-group"
                >
                  <Form.Label className="form-label">
                    Reference Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Reference Number"
                    name="reference_no"
                    value={formData.reference_no}
                    onChange={handleChange}
                    className="form-control"
                    disabled
                  />
                </Form.Group>
                <Form.Group controlId="formCategory" className="form-group">
                  <Form.Label className="form-label">Category</Form.Label>
                  <Form.Control
                    as="select"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    className="dropdown-toggle"
                  >
                    <option value="">Select Category</option>
                    {categories.map((category, index) => (
                      <option key={index} value={category}>
                        {category}
                      </option>
                    ))}
                  </Form.Control>
                  <FontAwesomeIcon icon={faCaretDown} className="caret-icon" />
                </Form.Group>
              </div>
              <Form.Group
                controlId="formShortDescription"
                className="form-group"
              >
                <Form.Label className="form-label">
                  Short Description
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Short Description"
                  name="short_description"
                  value={formData.short_description}
                  onChange={handleChange}
                  className="form-control"
                  onBlur={handleBlur}
                  isInvalid={touched.short_description && !formData.short_description} // Validation for short description
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Short description is required.
                </Form.Control.Feedback>
              </Form.Group>
              <div className="form-row">
                <Form.Group controlId="formValueOfRule" className="form-group">
                  <Form.Label className="form-label">Value of Rule</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Value of Rule"
                    name="value_of_rule"
                    value={formData.value_of_rule}
                    onChange={handleChange}
                    className="form-control"
                  />
                </Form.Group>
                <Form.Group controlId="formNewDRG" className="form-group">
                  <Form.Label className="form-label">New DRG</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter New DRG"
                    name="new_drg"
                    value={formData.new_drg}
                    onChange={handleChange}
                    className="form-control"
                  />
                </Form.Group>
              </div>
              <Form.Group
                controlId="formLongDescription"
                className="form-group"
              >
                <Form.Label className="form-label">Long Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter Long Description"
                  name="long_description"
                  value={formData.long_description}
                  onChange={handleChange}
                  className="form-control"
                  onBlur={handleBlur}
                  isInvalid={touched.short_description && !formData.long_description} // Validation for short description
                  required
                />
                <Form.Control.Feedback type="invalid">
                  long description is required.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formUserMessage" className="form-group">
                <Form.Label className="form-label">User Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter User Message"
                  name="user_message"
                  value={formData.user_message}
                  onChange={handleChange}
                  className="form-control"
                  onBlur={handleBlur}
                  isInvalid={touched.user_message && !formData.user_message} // Validation for user message
                  required
                />
                <Form.Control.Feedback type="invalid">
                  User message is required.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formRule2" className="form-group">
                {" "}
                {/* Updated controlId */}
                <Form.Label className="form-label">Rule 1</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Rule 1"
                  name="rule1"
                  value={formData.rule1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.rule1 && !formData.rule1} // Validation for rule1
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Rule 1 is required.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formRule2" className="form-group">
                {" "}
                {/* Updated controlId */}
                <Form.Label className="form-label">Rule 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Rule 2"
                  name="rule2"
                  value={formData.rule2}
                  onChange={handleChange}
                  className="form-control"
                />
              </Form.Group>
              <Form.Group controlId="formDRGVersion" className="form-group">
                <Form.Label className="form-label">DRG Version</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter DRG Version"
                  name="drg_version"
                  value={formData.drg_version}
                  onChange={handleChange}
                  className="form-control"
                />
              </Form.Group>
              <Form.Group controlId="formPriorityValue" className="form-group">
                {" "}
                {/* Updated controlId */}
                <Form.Label className="form-label">Priority Value</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Priority Value"
                  name="priority_value"
                  value={formData.priority_value}
                  onChange={handleChange}
                  className="form-control"
                />
              </Form.Group>

              <hr />
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleCloseModal}
                  className="btn-secondary"
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit" className="btn-primary">
                  Create
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
      {/* modal form for add category */}
      <div>
        <Modal
          show={showCategoryModal}
          onHide={handleCloseCategoryModal}
          backdrop="static"
          keyboard={false}
          className="custom-modal"
          dialogClassName="modal-dialog-centered"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleCategorySubmit}>
              <div className="form-row">
                <Form.Group controlId="formCategory" className="form-group">
                  <Form.Label className="form-label">Category</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Category"
                    name="category"
                    value={categoryFormData.category}
                    onChange={handleCategoryChange}
                    className="form-control"
                  />
                </Form.Group>
                <Form.Group controlId="formCategoryCode" className="form-group">
                  <Form.Label className="form-label">Category Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Category Code"
                    name="category_code"
                    value={categoryFormData?.category_code?.toUpperCase()}
                    onChange={handleCategoryChange}
                    className="form-control"
                  />
                </Form.Group>
              </div>
              <hr />
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleCloseCategoryModal}
                  className="btn-secondary"
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit" className="btn-primary">
                  Add
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
      {/* modal form for update audit rule */}
      <div>
        <Modal
          show={showUpdateAuditModal}
          onHide={handleCloseUpdateAuditModal}
          backdrop="static"
          keyboard={false}
          className="custom-modal"
          dialogClassName="modal-dialog-centered"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Audit Rule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleUpdateAuditSubmit}>
              <div className="form-row">
                <Form.Group
                  controlId="formReferenceNumber"
                  className="form-group"
                >
                  <Form.Label className="form-label">
                    Reference Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Reference Number"
                    name="reference_no"
                    value={updateFormData.reference_no}
                    onChange={handleUpdateChange}
                    className="form-control"
                    disabled
                  />
                </Form.Group>
              </div>
              <Form.Group
                controlId="formShortDescription"
                className="form-group"
              >
                <Form.Label className="form-label">
                  Short Description
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Short Description"
                  name="short_description"
                  value={updateFormData.short_description}
                  onChange={handleUpdateChange}
                  className="form-control"
                  onBlur={handleBlur}
                  isInvalid={touched.short_description && !updateFormData.short_description} // Validation for short description
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Short description is required.
                </Form.Control.Feedback>
              </Form.Group>
              <div className="form-row">
                <Form.Group controlId="formValueOfRule" className="form-group">
                  <Form.Label className="form-label">Value of Rule</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Value of Rule"
                    name="value_of_rule"
                    value={updateFormData.value_of_rule}
                    onChange={handleUpdateChange}
                    className="form-control"
                  />
                </Form.Group>
                <Form.Group controlId="formNewDRG" className="form-group">
                  <Form.Label className="form-label">New DRG</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter New DRG"
                    name="new_drg"
                    value={updateFormData.new_drg}
                    onChange={handleUpdateChange}
                    className="form-control"
                  />
                </Form.Group>
              </div>
              <Form.Group
                controlId="formLongDescription"
                className="form-group"
              >
                <Form.Label className="form-label">Long Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter Long Description"
                  name="long_description"
                  value={updateFormData.long_description}
                  onChange={handleUpdateChange}
                  className="form-control"
                  onBlur={handleBlur}
                  isInvalid={touched.long_description && !updateFormData.long_description} // Validation for long description
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Long description is required.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formUserMessage" className="form-group">
                <Form.Label className="form-label">User Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter User Message"
                  name="user_message"
                  value={updateFormData.user_message}
                  onChange={handleUpdateChange}
                  className="form-control"
                  onBlur={handleBlur}
                  isInvalid={touched.user_message && !updateFormData.user_message} // Validation for user message
                  required
                />
                <Form.Control.Feedback type="invalid">
                  User message is required.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formRule2" className="form-group">
                {" "}
                {/* Updated controlId */}
                <Form.Label className="form-label">Rule 1</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Rule 1"
                  name="rule1"
                  value={updateFormData.rule1}
                  onChange={handleUpdateChange}
                  onBlur={handleBlur}
                  isInvalid={touched.rule1 && !updateFormData.rule1} // Validation for rule1
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Rule 1 is required.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formRule2" className="form-group">
                {" "}
                {/* Updated controlId */}
                <Form.Label className="form-label">Rule 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Rule 2"
                  name="rule2"
                  value={updateFormData.rule2}
                  onChange={handleUpdateChange}
                  className="form-control"
                />
              </Form.Group>
              <Form.Group controlId="formDRGVersion" className="form-group">
                <Form.Label className="form-label">DRG Version</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter DRG Version"
                  name="drg_version"
                  value={updateFormData.drg_version}
                  onChange={handleUpdateChange}
                  className="form-control"
                />
              </Form.Group>

              <Form.Group controlId="formPriorityValue" className="form-group">
                {" "}
                {/* Updated controlId */}
                <Form.Label className="form-label">Priority Value</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Priority Value"
                  name="priority"
                  value={updateFormData.priority}
                  onChange={handleUpdateChange}
                  className="form-control"
                />
              </Form.Group>

              <hr />
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleCloseUpdateAuditModal}
                  className="btn-secondary"
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit" className="btn-primary">
                  save
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default AuditRuleDetails;
