import React, { useState,useEffect } from "react";
import "./MainSideBar.css";
import { NavLink, useLocation } from "react-router-dom";
import { CircleImg } from "../../common/assets/svg/CircleBlack";
import SidebarLogo from "../../common/assets/images/imdexlogo.png";
import MagicWand from "../../assets/magic-wand-01.png";
import RouteSymbol from "../../assets/route.png";
import Stars from "../../assets/stars-01.png";
import Rocket from "../../assets/rocket-02.png";
import { ArrowDown } from "../../common/assets/svg/ArrowBlack";
import { BiLoader } from "react-icons/bi";
import axios from "axios";
import ArrowRight from "../../common/assets/svg/ArrowRight";
import ChatSideBar from "./ChatSideBar";
import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../authContext/AuthContext";
import { configData, adminConfigData } from "../navbar/config";
import NotificationDropdown from "../NotificationDropdown/NotificationDropdown";


const MainSidebar = ({
  setMode,
  mode,
  selectedCompanion,
  setSelectedCompanionState,
  style
}) => {
  const { userEmail } = useAuth();
  const location = useLocation();
  const isChatAi = ["/chat-ai/", "/chat-ai"].includes(location.pathname);
  const name = String(userEmail)?.split("@")[0];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const permissions = JSON.parse(sessionStorage.getItem("permissions"))
  const adminUser =permissions&& permissions.admin
  const [navConfigData, setNavConfigData] = useState(configData)
  const [showAdminConfigData, setShowAdminConfigData] = useState(false)
  let adminRoutes = [
    "/users",
    "/role-management",
    "/module-management",
    "/role-module-permission",
    "/user-role-assignment",
    "/manage-organization",
    "/manage-countries"
  ]
 
  useEffect(()=>{ 
    if(adminRoutes.includes(location.pathname)){
      setShowAdminConfigData(true);
      //  document.querySelector(".selected-companion").innerText = "Users"
    }
  },[])

  useEffect(() => {
    const userPermissions = Object.entries(permissions).reduce((acc, [key, value]) => {
      if (typeof value === 'boolean' && value || Array) {
        acc.push(key);}
      return acc;
  }, []);

  //console.log(userPermissions,"=============================================================================" ,typeof(userPermissions));

  const isAdmin = permissions.admin === true;
  // const filteredConfigData = configData.filter(item => {
  //   return userPermissions.includes(item.name);
   
  // });
  const filteredConfigData = isAdmin
  ? configData // If admin, include all config data
  : configData.filter(item => userPermissions.includes(item.name) || item.name === "DiscoverFeatures");

//console.log(filteredConfigData);

  //console.log(filteredConfigData);
  if(showAdminConfigData){
    setNavConfigData(adminConfigData);}else{setNavConfigData(filteredConfigData);}
  }, [showAdminConfigData]);
  
  const getImageSource = (header) => {
    switch (header) {
      case "Users":
        return ;
      case "Roles":
        return ;
      case "Modules":
        
      return ;
      case "Module Permissions":
        return ;
      case "Role Assignments":
        return ;
        case "Discovery Companion":
        return MagicWand;
      case "Workflow Companion":
        return RouteSymbol;
      case "AI Companion":
        return Stars;
      case "Discover Features":
        return Rocket;
      case "Organizations":
        return ;    
     case "Countries":
        return ;
      default:
        return "";
    }
  };

  const handleMode = () => {
    const newMode = mode === "DARK" ? "LIGHT" : "DARK";
    setMode(newMode);
    sessionStorage.setItem("mode", newMode);
  };

  const handleLogoutClick = () => {
    setLoading(true);
    const postapiEndpoint = "";
    const accessToken = sessionStorage.getItem("accessToken");
    const postpayload = {
      body: {
        session_token: accessToken,
      },
    };
   // Check if an API endpoint exists (future use case)
  if (postapiEndpoint) {
    // Perform API-based logout
    axios
      .post(postapiEndpoint, postpayload)
      .then((response) => {
        //console.log("Logout request successful:", response);

        // Clear sessionStorage and handle logout
        sessionStorage.clear();
        window.location.reload(); // Or navigate to the login page
      })
      .catch((error) => {
       console.log("Error in logout request:", error);
        setError(error.message);
      })
      .finally(() => {
        setLoading(false); // Stop loading spinner
      });
  } else {
    // Manual logout (no API endpoint available)
    //console.log("Manual logout");

    // Clear sessionStorage manually
    sessionStorage.clear();

    // Update state and reload the app or navigate to the login page
    window.location.reload(); // Optionally use `navigate("/login")` if preferred
    // or use: navigate("/login");
    setLoading(false);
  }
  };

  const isSettingsPage = location.pathname === "/powerapp";
  const handleAdminSettingsClick = () => { 
    setShowAdminConfigData(!showAdminConfigData);
   };
  return (
    <div className=" w-20 text-white" style={style}>
    <div className="main-sidebar h-100">
      <div>
        <div className="sidebar-wrapper-notification">
        <img className="p-3" src={SidebarLogo} alt="sidebarlogo" />
        {/* <NotificationDropdown/> */}
        </div>
        <ul className="sidebar-companion-toggle-btn">
          {navConfigData.map((route,index) => {
            const isActive = location.pathname === route.path;
            return (
              <li key={index}
                className={isActive ? "active" : ""}
                onClick={() => setSelectedCompanionState(route.header)}
              > 
                <div>
                  <span>
                    <img
                      src={getImageSource(route.header)}
                      alt=""
                    />
                  </span>
                  <NavLink to={route.path}>{route.header}</NavLink>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      {isChatAi && <ChatSideBar />}
      <div className="sidebar-wrapper-help">
        <ul className="setting">
          <NavLink to="/powerapp" activeClassName="active">
            <li className={isSettingsPage ? "active" : ""}>Settings</li>
          </NavLink>
          
          <li className="tooltip-container">Help<span className="tooltip-text p-2">Contact:  <a>ics-support@imedx.com.au</a></span></li>
          <li>
            <div className="d-flex align-items-center gap-1">
              <span>{mode === "DARK" ? "Light Mode" : "Dark Mode"}</span>
              <div>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={mode === "DARK"}
                    onChange={handleMode}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </li>
          <li className="user-details">
            <span className="user-icon">
              {" "}
              <CircleImg />
            </span>
            <div>
              <div> {name}</div>
              <span className="landingpage-user-email">{userEmail}</span>
              <span className="landingpage-arrow-down">
                <ArrowDown />
                <div className="landingpage-dropdown-content">
                  <div>
                    <span
                      className="landing-logout-btn"
                      onClick={handleLogoutClick}
                    >
                      <ArrowRight />
                      {loading ? <BiLoader /> : "Logout"}
                    </span>
                  </div>
                  <div>
                  <Link to="feedback">
                    <span>Feedback</span>
                  </Link>
                </div>
                <div>
                  <Link to="user-feedbacks">
                    <span>User Feedback</span>
                  </Link>
                </div>
                {adminUser && <div>
                  <Link to={showAdminConfigData?"discover-features":"users"} onClick={handleAdminSettingsClick}>
                    <span>{showAdminConfigData? "User Settings" : "Admin Settings"}</span>
                  </Link>
                </div>}
                </div>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    </div>
  );
};

export default MainSidebar;
