import React from 'react'
import notifications from './notificationsdummy'
import download_icon from '../../../src/assets/download-03@2x.png'
const NotificationDetails = () => {

  return (
    <div className='m-auto h-100  hidden-scroll mt-5 notification-table'>
      {/* <h4 className='mb-3'>Notification Details</h4> */}
      {/* <div className='d-flex flex-column gap-2'>
         {notifications.map((eachNotification)=>{
            return <div style={{borderBottom:'1px solid #eaeaea'}} className='notification-details-wrapper'>
              <div className='d-flex align-items-center'>
                <div className='notification-userbadge'>{eachNotification.username.charAt(0).toUpperCase()}</div>
                <div className='notification-detail-subwrapper'>
                <span>{eachNotification.username} Successfully uploaded a file</span>
                <div className='subcontent-wrapper'>
                <div>{eachNotification.dateandtime},</div>
                <div>{eachNotification.fileName},</div>
                <div>{eachNotification.fileSize},</div>
                </div>
               
                </div>
             </div>
             <div className='d-flex justify-content-center gap-1' style={{fontSize:'14px',cursor:'pointer'}}>
              <p style={{marginBottom:'0px'}}>{eachNotification.fileName}</p>
              <img src={download_icon} alt='download'/>
             </div>
            </div>
         })}
      </div> */}
      <table>
        <thead>
          <tr>
            <th>Document Name</th>
            <th>Uploaded By</th>
            <th>Upload Date</th>
            <th>Upload Time</th>
            <th>Size</th>
            <th>{" "}</th>
          </tr>
        </thead>
        <tbody>
          {notifications.map((eachNotification)=>{
           return ( <tr>
              <td>{eachNotification.fileName}</td>
              <td>{eachNotification.username}</td>
              <td>{eachNotification.dateandtime}</td>
              <td>{eachNotification.dateandtime}</td>
              <td>{eachNotification.fileSize}</td>
              <td>
                <button className='notification-download-btn'>
                <img alt='download' src={download_icon}/>Download</button>
              </td>
            </tr>)
          })}
        </tbody>
      </table>
    </div>
  )
}

export default NotificationDetails
