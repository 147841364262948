import React from "react";
import { initialMessage } from "../../utils/Constants";
import ChatIcon from "../../assets/chaticon.png";

const InitialMessageSection = ({ handleMessageClick }) => {
  
  return (
    <>
      <div className="code-chat">
        <div>
          <div className="d-flex justify-content-center">
            <img src={ChatIcon} alt="HelpIcon" />
          </div>
          <p style={{color:"#032F49"}}>How can I help you today?</p>
        </div>
      </div>
      <div className="chatText">
        {initialMessage.map((message,idx) => {
          return (
            <div key={idx}>
             <div> 
              <span>{message.title}</span>
              <p>{message.description}</p>
              </div>
              <div>
                <img
                  src={message.imgSrc}
                  alt={message.altContent}
                  onClick={() => handleMessageClick(message.title)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default InitialMessageSection;
