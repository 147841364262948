import React, { useEffect, useState } from "react";
import "./Header.css";
import Logo from "../../assets/logo.png";
import axios from "axios";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BiLoader } from "react-icons/bi";
import Gear from "../../assets/svg/Gear";
import { Spinner } from "../../../web-workflow/common/Helper";
import Archive from "../../assets/svg/Archive";
import axiosInstance from "../../../../../axiosInterceptor";
import InfiniteScroll from "../../../codercompanion/components/infinite-scroll";
import deleteIcon from "../../../chat-ai/assets/delete.png"

function Header({
  setError,
  isOpen,
  setIsOpen,
  getConservations,
  archivedChats,
  fetchArchivedConversations,
}) {
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleSettingsModal = () => {
    setIsSettingsModalOpen(!isSettingsModalOpen);
  };

  useEffect(() => {
    fetchArchivedConversations();
  }, []);

  //console.log(archivedChats, "9:::");
  const handleUnarchiveClick = (conversationChat) => {
    const archiveApiEndPoint =
      "https://02edqwa2s3.execute-api.ap-southeast-2.amazonaws.com/llm_archive_unarchive_ics_v1";
    const accessToken = sessionStorage.getItem("accessToken");
    const archivePayload = {
        conversation_id: conversationChat.conversation_id,
        archive: false,
    };
    setLoading(true)
    axiosInstance
      .put(archiveApiEndPoint, archivePayload)
      .then((response) => {
        //console.log("unArchived Conversations successful:", response);
        getConservations();
        fetchArchivedConversations();
      })
      .catch((error) => {
       console.log("Error in unArchived request:", error);
      }).finally(() => {
        setLoading(false)
      });
  };
  return (
    <>
      <div className="header-wrapper">
        <div>
        <img src = {deleteIcon} alt="deleteIcon" style={{width:"15px",height:"16px"}}/>
          <span className="settings-btn" onClick={toggleSettingsModal}>
            Archived Chats
          </span>
        </div>
        <div
          className={`modal fade ${isSettingsModalOpen ? "show" : ""}`}
          style={{ display: isSettingsModalOpen ? "block" : "none" }}
          aria-hidden={!isSettingsModalOpen}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Archived Chats</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={toggleSettingsModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body archived-chats">
                <InfiniteScroll>
                <div className="archived-chat">
                  {loading && <Spinner />}
                  {archivedChats && archivedChats.length > 0 ?
                    archivedChats.map(
                      (conversationChat, index) => {
                        return (
                          <div className="archived-chat-wrapper">
                            <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-chat"
                                viewBox="0 0 16 16"
                              >
                                <path d="M2.678 11.894a1 1 0 0 1 .287.801 11 11 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8 8 0 0 0 8 14c3.996 0 7-2.807 7-6s-3.004-6-7-6-7 2.808-7 6c0 1.468.617 2.83 1.678 3.894m-.493 3.905a22 22 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a10 10 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9 9 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105" />
                              </svg>
                            </div>
                            <div className="d-flex gap-3 archive-color">
                             
                              <p>{conversationChat.conversation}</p>
                            </div>
                            <div className="d-flex gap-3 archive-delete-btns">
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Unarchive this conversation
                                  </Tooltip>
                                }
                              >
                                <span
                                  className="unarchive-btn"
                                  onClick={() =>
                                    handleUnarchiveClick(conversationChat)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-box-arrow-up"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M3.5 6a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 1 0-1h2A1.5 1.5 0 0 1 14 6.5v8a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-8A1.5 1.5 0 0 1 3.5 5h2a.5.5 0 0 1 0 1z"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      d="M7.646.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 1.707V10.5a.5.5 0 0 1-1 0V1.707L5.354 3.854a.5.5 0 1 1-.708-.708z"
                                    />
                                  </svg>
                                </span>
                              </OverlayTrigger>
                            </div>
                          </div>
                        );
                      }
                    ):"No Chats Archived"}
                </div>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
        {/* Optional backdrop for modal */}
        <div
          className={`modal-backdrop fade ${isSettingsModalOpen ? "show" : ""}`}
          style={{ display: isSettingsModalOpen ? "block" : "none" }}
        ></div>
      </div>
    </>
  );
}

export default Header;
