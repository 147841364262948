// src/components/Modal/Modal.js
import React from 'react';
import './Modal.css';

const Modal = ({ title, label, name, setName, onSave, onClose }) => {
  return (
    <div className="modal-overlay-role z-3">
      <div className="modal-content-common-role">
        <h4 className="modal-title-role">{title}</h4>
        <label className="modal-role-role">
           {label}:
          <input
          className="modal-input-role"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </label>
        <div className="modal-actions-role">
          <button className="btn-role btn-primary-save-role" onClick={onSave}>
            Save
          </button>
          <button className="btn-role btn-secondary-cancel-role" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
