
// export const configData = [
//   {
//     name: "DISCOVERY_COMPANION",
//     path: "/discovery-companion",
//     header: "Discovery Companion",
//   },
//   {
//     name: "WORKFLOW_COMPANION",
//     path: "/workflow-companion",
//     header: "Workflow Companion",
//   },
//   {
//     name: "AI_COMPANION",
//     path: "/chat-ai",
//     header: "AI Companion",
//   },
//   {
//     name: "DISCOVER_FEATURES",
//     path: "/discover-features",
//     header: "Discover Features",
//   },
// ];

export const configData = [
  {
    name: "DiscoveryCompanion",
    path: "/discovery-companion",
    header: "Discovery Companion",
  },
  {
    name: "WorkflowCompanion",
    path: "/workflow-companion",
    header: "Workflow Companion",
  },
  {
    name: "AICompanion",
    path: "/chat-ai",
    header: "AI Companion",
  },
  {
    name: "DiscoverFeatures",
    path: "/discover-features",
    header: "Discover Features",
  },
];
export const adminConfigData = [
  {
    name: "Users",
    path: "/users",
    header: "Users",
  },
    {
      name: "ROLE_MANAGEMENT",
      path: "/role-management",
      header: "Roles",
    },
    {
      name: "Module_Management",
      path: "/module-management",
      header: "Modules",
    },
    {
      name: "ROLE_MODULE_PERMISSION",
      path: "/role-module-permission",
      header: "Module Permissions",
    },
    {
      name: "USER_ROLE_ASSIGNMENT",
      path: "/user-role-assignment",
      header: "Role Assignments",
    },
    {
      name: "MANAGE_ORGANIZATION",
      path: "/manage-organization",
      header: "Organizations",
    },
    {
      name: "MANAGE_COUNTRIES",
      path: "/manage-countries",
      header: "Countries",
    },
  ];
