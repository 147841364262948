
import React, { useEffect, useState } from "react";
import Modal from "../modal/Modal";
import "./RoleManagement.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../axiosInterceptor";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const RoleManagement = () => {
  const [roles, setRoles] = useState();
  const [selectedRole, setSelectedRole] = useState(null);
  const [roleName, setRoleName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(null); // Store the role to be deleted
  const [users, setUsers] = useState([]);

  const handleAddRole = async () => {
    if (!roleName.trim()) {
      toast.error("Role name cannot be empty!");
      return;
    }
    axiosInstance
      .post(
        "https://b7r0ilovh4.execute-api.ap-southeast-2.amazonaws.com/post_admin_roles/",
        { roleName, createdBy: sessionStorage.userId }
      )
      .then((response) => {
        toast.success(response.data.message);
        setShowModal(false);
        setRefresh(!refresh);  // Trigger re-fetch
      })
      // .catch((err) => {
      //   setShowModal(false);
      // });

      .catch(err => {
        // Check for duplicate key error
        if (err.response && err.response.data && err.response.data.error) {
          const errorMessage = err.response.data.error;
          if (errorMessage.includes("duplicate key value violates unique constraint")) {
            toast.error("Role name already exists. Please choose a different name.");
          } else {
            toast.error("An error occurred while adding the module.");
          }
        } else {
          toast.error("Unable to connect to the server. Please try again.");
        }
        setShowModal(false);
      });
  };

  const handleEditRole = (role) => {
    setSelectedRole(role);
    setRoleName(role.role_name);
    setShowModal(true);
  };

  const handleUpdateRole = () => {
    axiosInstance
      .put(
        "https://0shkvvi07b.execute-api.ap-southeast-2.amazonaws.com/update_admin_roles/",
        {
          newRoleName: roleName,
          newupdatedBy: sessionStorage.userId,
          roleId: selectedRole.role_id,
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        setShowModal(false);
        setRefresh(!refresh);  // Trigger re-fetch
      })
      .catch((err) => {
        setShowModal(false);
      });
  };

  const handleDeleteRole = (role) => {
    // Set the role to be deleted and show the confirmation dialog
    setRoleToDelete(role);
    setShowConfirmDialog(true);
  };

  const confirmDelete = () => {
    if (roleToDelete) {
      axiosInstance
        .delete(
          `https://few0442gs4.execute-api.ap-southeast-2.amazonaws.com/delete_admin_roles/?roleId=${roleToDelete.role_id}`
        )
        .then((response) => {
          toast.success(response.data.message);
          setShowConfirmDialog(false);
          setRefresh(!refresh);  // Trigger re-fetch to update the role list
        })
        // .catch((err) => {
        //   toast.error("Failed to delete role");
        //   setShowConfirmDialog(false);
        // });
        .catch((err) => {
          setShowConfirmDialog(false);
  
          // Handle specific backend errors
          if (
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.includes("violates foreign key constraint")
          ) {
            toast.error("This role is assigned to modules and cannot be deleted.");
          } else {
            toast.error("Failed to delete role. Please try again.");
          }
        });
    }
  };

  const cancelDelete = () => {
    // Close the confirmation dialog without deleting
    setShowConfirmDialog(false);
  };

  useEffect(() => {
    axiosInstance
      .get(
        "https://7t0ywwlaqb.execute-api.ap-southeast-2.amazonaws.com/get_admin_roles/"
      )
      .then((response) => {
        // setRoles(response.data.data);
        const roles = response.data.data;

    // Filter out the "admin" role
    const filteredRoles = roles.filter(
      (role) => role.role_name.toLowerCase() !== "admin\n"
    );

    // Update the state with the filtered roles
    setRoles(filteredRoles);
      })
      .catch((err) => {
        console.log("Error: " + err);
      });

  //     axiosInstance.get("https://nxbllqi022.execute-api.ap-southeast-2.amazonaws.com/admin_role_module_permissions/")
  // .then(response => {
  //   setUsers(response.data.data);  // Store users data
  // }).catch(err => {
  //   console.log("Error fetching users: " + err);
  // });
  }, [refresh]);

   // Check if the organization is assigned to any user
  //  const isOrgAssigned = (role) => {
  //   return users.some(user => user.role_id === role);
  // };
  return (
    <div className="role-management-container">
      <ToastContainer autoClose={20000} />
    
      <div className="header-section-role">
        {/* <h1 className="title-left-role">Manage Roles</h1> */}
        <button className='my-2 add-role-btn' onClick={()=>setRefresh(!refresh)}>Refresh Data</button>
        <button
          className="btn add-role-btn"
          onClick={() => {
            setShowModal(true);
            setSelectedRole(null);
          }}
        >
          Add Role
        </button>
      </div>
      <table className="role-table">
        <thead>
          <tr>
            <th>Role ID</th>
            <th>Role Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {roles ? (
            roles.map((role,index) => (
              <tr key={index}>
                <td>{role.role_id}</td>
                <td>{role.role_name}</td>
                <td>
                  <button onClick={() => handleEditRole(role)}>
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      className="iconuser"
                    />
                  </button>
                  <button onClick={() => handleDeleteRole(role)}>
                    <FontAwesomeIcon icon={faTrash} className="iconuser" />
                  </button>
                    {/* <button
                  className={` ${isOrgAssigned(role.role_id) ? 'disabled-org-btn' : ''}`}
                    onClick={() => handleDeleteRole(role)}
                   disabled={isOrgAssigned(role.role_id)} // Disable the button
                        >
                       <FontAwesomeIcon icon={faTrash} className="iconuser" />
                          </button> */}
                </td>
              </tr>
            ))
          ) : (
            <div className="container">Loading....</div>
          )}
        </tbody>
      </table>

      {showModal && (
        <Modal
          name={roleName}
          setName={setRoleName}
          onSave={selectedRole ? handleUpdateRole : handleAddRole}
          onClose={() => setShowModal(false)}
          title={selectedRole ? "Edit Role" : "Add Role"}
          label="Role Name"
        />
      )}

      {/* Confirm Delete Dialog */}
      {showConfirmDialog && (
        <div className="modal-organization">
          <div className="modal-content-organization">
            <h3>Confirm Delete</h3>
            <p>Are you sure you want to delete this Role?</p>
            <div className="modal-footer-organization">
              <button className="btn-organization save-organization" onClick={confirmDelete}>Delete</button>
              <button className="btn-organization cancel-organization" onClick={cancelDelete}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RoleManagement;

