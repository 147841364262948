import React,{useState,useEffect} from 'react'; 
import "./LandingCards.css";
import { useNavigate } from 'react-router-dom';
import { landingPageData } from './Config';
import { useChatContext } from '../../../context';
import { configData } from '../config';



const LandingCards = () => {
    const navigate = useNavigate();
    const {common:{setSelectedCompanionState}} = useChatContext();

    const [navConfigData, setNavConfigData] = useState(configData);
    const [showPermissionPopup, setShowPermissionPopup] = useState(false);
    const [permissionPopupMessage, setPermissionPopupMessage] = useState('');
    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    const isAdmin = permissions && permissions.admin === true;
   
    useEffect(() => {
      // Extract user permissions
      const userPermissions = Object.entries(permissions).reduce((acc, [key, value]) => {
        if (Array.isArray(value) || (typeof value === 'boolean' && value)) {
            acc.push(key); // Add the key if its value is an array or `true`
        }
        return acc;
    }, []);
      // Filter config data based on user permissions, always include "Discover"
      const filteredConfigData = isAdmin
      ? configData // If admin, include all config data
      : configData.filter(item => userPermissions.includes(item.name) || item.name === "DiscoverFeatures");
      setNavConfigData(filteredConfigData);
  
    }, []);
    const handleCardClick = (data) => {

      // const {route,title} = data;
      const { route, title, name } = data; // Destructure module data

      // Check if the user has permission for the clicked module using filteredConfigData
      const hasPermission = navConfigData.some((item) => item.name === name);

    
      if (!hasPermission) {
        // Show a popup if the user lacks permission for the module
        setShowPermissionPopup(true);
        setPermissionPopupMessage(
          `You don't have permission to access "${title}". \n Please contact the admin.`
        );
        return;
      }
      setSelectedCompanionState(title)
      navigate(route);
    };
    const handleDemoClick = (demoLink) => {
      window.open(demoLink, '_blank');
    };
  return (
    <div className='landingpage-wrapper'>
      {landingPageData.map((data, index) => (
        <div className={`main-landing-page${data.id}`} key={index}  onClick={() => handleCardClick(data)}>
          <div className='left-pagecoder'>
            <h2>{data.title}</h2>
            <p>{data.description}</p>
            <img src={data.image} alt={`logo${data.id}`} />
          </div>
          <div className='right-pagecoder'>
            <p>{data.rightDescription}</p>
            <ul>
              {data.features.map((feature, idx) => (
                <li key={idx}><a>{feature}</a></li>
              ))}
            </ul>
            <div className='internal-page'>
              <button>
                <img src={data.button1.icon} alt='tick' /> {data.button1.text}
              </button>
              <button className='button-demo' onClick={(e) => { 
                e.stopPropagation(); 
                handleDemoClick(data.demoLink); 
              }}>
                <img src={data.button2.icon} alt='tack' /> {data.button2.text}
              </button>
            </div>
          </div>
        </div>
      ))}

          {/* Permission Denied Popup */}
          {showPermissionPopup && (
                <div className="permission-popup">
                    <div className="popup-content">
                        <p>{permissionPopupMessage}</p>
                        <button onClick={() => setShowPermissionPopup(false)}>Close</button>
                    </div>
                </div>
            )}
    </div>
  );
}

export default LandingCards;
